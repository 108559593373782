import { React, useState, useEffect } from "react";
import { Navbar, Container, Nav, Table, Form, InputGroup, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import Swal from 'sweetalert2'
import { useNavigate, useParams } from "react-router-dom";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import api from "../instants";

function Agent_CheckUser() {
    const navigate = useNavigate();
    const param = useParams()
    const [userList, setUserList] = useState([])
    const [sumPrice, setSumPrice] = useState([])

    async function fetchData() {
        await fetch('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Get_All_Users',{
            headers:{
                'authorization': localStorage.getItem('ACCESS_TOKEN'),
            }
        })
        .then((response) => response.json())
        .then((data) => setUserList(data))
        .catch((err) => {
            console.log(err);
            navigate('/')
        });
    }
    
    useEffect(() => {
        fetchData()
    }, [])

    const handleChange = (username) => {
        navigate('/Agent_Main/' + param.id + '/Agent_CheckUser/Agent_EditUser/' + username)
    }

    const handleDelete = async (id, user) => {
        Swal.fire({
            title: 'ลบข้อมูล',
            text: "คุณต้องการจะลบข้อมูลนี้ใช่ไหม",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ยกเลิก',
            allowOutsideClick: false
          }).then( async (result) => {
            if (result.isConfirmed) {
                await api.post('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Delete_User', {id: id,user: user})
                window.location.reload()
            }
        })
    }


    return (
        <div>
            {/* navbar */}
            {/* <Container> */}
            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
                    <Container>
                    <Navbar.Brand onClick={() => navigate("/Agent_Main/" + param.id)}>หน้าหลัก</Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link onClick={() => navigate("/Agent_Main/" + param.id + "/Agent_SubData")}>ดูข้อมูลอย่างละเอียด</Nav.Link>
                                <Nav.Link onClick={() => navigate("/Agent_Main/" + param.id + "/Agent_CheckUser")}>ดูข้อมูลผู้ใช้งาน</Nav.Link>
                                <Nav.Link onClick={() => navigate("/Agent_Main/" + param.id + "/Agent_CreateUser")}>สร้างผู้ใช้งาน</Nav.Link>
                                <Nav.Link onClick={() => navigate("/Agent_Main/" + param.id + "/Agent_PayRate")}>อัตราจ่าย-ส่วนลด</Nav.Link>
                            </Nav>
                            <Nav>
                                <Navbar.Text>ชื่อผู้ใช้งาน: {param.id}</Navbar.Text>
                                <Nav.Link onClick={() => navigate("/")}>Logout</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            {/* </Container> */}

            {/* show data */}
            <Container>
                <Table>
                    <thead>
                        <tr>
                            <th>ชื่อผู้ใช้งาน</th>
                            <th>รหัสผ่าน</th>
                            <th>สถานะ</th>
                            <th>เพิ่มโดย</th>
                            <th>ชื่อเล่น</th>
                            <th>ชื่อจริง</th>
                            <th>เบอร์โทร</th>
                            <th>ราคาแทงรวมส่วนลด</th>
                            <th>แก้ไข</th>
                            <th>ลบ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userList.filter((item) => !item.username.includes("admin") && item.addby === param.id).map((item) => (
                            <tr key={item.id}>
                                <td>{item.username}</td>
                                <td>{item.password}</td>
                                <td>{item.roll}</td>
                                <td>{item.addby}</td>
                                <td>{item.name}</td>
                                <td>{item.fullname}</td>
                                <td>{item.phonenumber}</td>
                                <td>{parseInt(Number(item.sumprice))}</td>
                                <td><PencilSquare onClick={() => handleChange(item.username)}/></td>
                                <td><Trash variant="danger" onClick={() => handleDelete(item.id, item.username)}/></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>
        </div>
    )
}

export default Agent_CheckUser