import { React, useState, useEffect } from "react"
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios'
import { Trash } from 'react-bootstrap-icons'
import { Container, Navbar, Button, Table, Nav, Form, InputGroup, Col } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';
import api from "../instants";

function User_History() {
    const navigate = useNavigate()
    const param = useParams()
    const [searchhuai, setSearchHuai] = useState('')
    const [searchtype, setSearchType] = useState('')
    const [payhalfNumber, setPayhalfNumber] = useState([])

    // fetch payrate data and user data from backend
    const [payrate, setpayrate] = useState('')
    const [numberList, setNumberList] = useState([])
    const fetchData = async () => {
        await fetch('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Get_PayRate/' + param.id,{
            headers:{
                'authorization': localStorage.getItem('ACCESS_TOKEN'),
            }
        })
        .then((response) => response.json())
        .then((data) => setpayrate(data))
        .then(async () => {
            await fetch('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Get_Record/' + param.id,{
                headers:{
                    'authorization': localStorage.getItem('ACCESS_TOKEN'),
                }
            })
            .then((response) => response.json())
            .then((data) => setNumberList(data))
        }).catch((err) => {
            console.log(err);
            navigate('/')
        });
    }

    const fetchPayhalfNumber = async () => {
        await fetch('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Get_PayhalfNumber',{
            headers:{
                'authorization': localStorage.getItem('ACCESS_TOKEN'),
            }
        })
        .then((response) => response.json())
        .then((data) => setPayhalfNumber(data))
        .catch((err) => {
            console.log(err);
            navigate('/')
        });
    }

    useEffect(() => {
        fetchData()
        fetchPayhalfNumber()
    }, []);

    // delete by date feature
    const handleDelete = (huainumber, type) => {
        Swal.fire({
            title: 'ลบการแทงหวย',
            text: "คุณต้องการจะลบการแทง " + type + ' ' + huainumber + ' ใช่หรือไม่',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ยกเลิก',
            allowOutsideClick: false
          }).then(async (result) => {
            if (result.isConfirmed) {
                await api.post('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Delete_Record', {
                    huainumber: huainumber,
                    type: type
                })
                Swal.fire({
                    icon: 'success',
                    title: 'ลบการแทงหวยสำเร็จ',
                    showConfirmButton: false,
                    timer: 1000
                })
                fetchData()
            }
        })
    }

    return (
        <div style={{
            position:'relative',
            display:'flex',
            flexDirection: 'column',
            alignItems:'center',
            left:'0%',
            top:'0%',
            width:'100%',
            height:'100%'}}>
            <video
                src='https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1263950/5d55e33857c40b4ae57aaba611cf7ce8a2b41c5b.mp4'
                autoPlay loop muted playsInline
                style={{
                    position:"absolute",
                    width:"100%",
                    height: numberList.length > 13 ? "100%" : '100vh',
                    objectFit:"cover",
                    zIndex:"-1"
                }}
            />
            <Container>
                <Navbar collapseOnSelect expand="sm" className="bg-body-tertiary">
                    <Container>
                        <Navbar.Brand  className='d-flex align-items-end' onClick={() => navigate("/User_Main/" + param.id)}>
                            <img src='/logo.png' width={50} height={50}/>
                            <Container>
                                <h3 style={{fontWeight:"bold"}}>ซื้อหวยออนไลน์</h3>
                            </Container>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                        <Navbar.Collapse className="justify-content-end">
                            <Nav>
                                <Navbar.Text>ชื่อผู้ใช้งาน: {param.id}</Navbar.Text>
                                <Nav.Link onClick={() => navigate("/User_Main/" + param.id)}>กลับหน้าหลัก</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Container>

            <Container>
                <Container>
                    <Form>
                        <InputGroup className='my-3'>
                            <Form.Control style={{marginRight:'5px'}} placeholder='ค้นหาเลข' type="number" onChange={(e) => setSearchHuai(e.target.value)}/>
                            <Form.Control style={{marginLeft:'5px', marginRight:"5px"}} placeholder='ค้นหาชนิด' type="text" onChange={(e) => setSearchType(e.target.value)}/>
                        </InputGroup>
                    </Form>
                </Container>
                
                <Table className="table-fixed">
                    <thead>
                        <tr>
                            <th>เลข</th>
                            <th>ชนิด</th>
                            <th>ราคา</th>
                            <th>อัตราจ่าย</th>
                            <th>เงินรางวัล</th>
                            <th>ลบ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {numberList.filter((item) => {
                            return searchhuai === '' && searchtype === '' ? item : item.huainumber.includes(searchhuai) && item.type.includes(searchtype)
                        }).map(item => (
                            <tr key={item.id}>
                                <td>{item.huainumber}</td>
                                <td>{item.type}</td>
                                <td>{item.price}</td>
                                <td>{
                                    item.type === 'หัว' ? item.reward === '1' ? payrate[0].ratehead / 2 : payrate[0].ratehead :
                                    item.type === 'ท้าย' ? item.reward === '1' ? payrate[0].ratetail / 2 : payrate[0].ratetail :
                                    item.type === 'โต๊ดหัว' ? item.reward === '1' ? payrate[0].ratetoadhead / 2 : payrate[0].ratetoadhead :
                                    item.type === 'โต๊ดท้าย' ? item.reward === '1' ? payrate[0].ratetoadtail / 2 : payrate[0].ratetoadtail :
                                    item.type === 'บน' ? item.reward === '1' ? payrate[0].ratetop / 2 : payrate[0].ratetop :
                                    item.type === 'ล่าง' ? item.reward === '1' ? payrate[0].ratebottom / 2 : payrate[0].ratebottom :
                                    item.type === '4 ครั้ง' ? item.reward === '1' ? payrate[0].ratefourtimes / 2 : payrate[0].ratefourtimes :
                                    item.type === 'วิ่งหัว' ? item.reward === '1' ? payrate[0].raterunhead / 2 : payrate[0].raterunhead :
                                    item.type === 'วิ่งท้าย' ? item.reward === '1' ? payrate[0].rateruntail / 2 : payrate[0].rateruntail :
                                    item.type === 'วิ่งบน' ? item.reward === '1' ? payrate[0].rateruntop / 2 : payrate[0].rateruntop :
                                    item.type === 'วิ่งล่าง' ? item.reward === '1' ? payrate[0].raterunbottom / 2 : payrate[0].raterunbottom : ''
                                }</td>
                                <td>{
                                    item.type === 'หัว' ? item.reward === '1' ? item.price * payrate[0].ratehead / 2 : item.price * payrate[0].ratehead :
                                    item.type === 'ท้าย' ? item.reward === '1' ? item.price * payrate[0].ratetail / 2 : item.price * payrate[0].ratetail :
                                    item.type === 'โต๊ดหัว' ? item.reward === '1' ? item.price * payrate[0].ratetoadhead / 2 : item.price * payrate[0].ratetoadhead :
                                    item.type === 'โต๊ดท้าย' ? item.reward === '1' ? item.price * payrate[0].ratetoadtail / 2 : item.price * payrate[0].ratetoadtail :
                                    item.type === 'บน' ? item.reward === '1' ? item.price * payrate[0].ratetop / 2 : item.price * payrate[0].ratetop :
                                    item.type === 'ล่าง' ? item.reward === '1' ? item.price * payrate[0].ratebottom / 2 : item.price * payrate[0].ratebottom :
                                    item.type === '4 ครั้ง' ? item.reward === '1' ? item.price * payrate[0].ratefourtimes / 2 : item.price * payrate[0].ratefourtimes :
                                    item.type === 'วิ่งหัว' ? item.reward === '1' ? item.price * payrate[0].raterunhead / 2 : item.price * payrate[0].raterunhead :
                                    item.type === 'วิ่งท้าย' ? item.reward === '1' ? item.price * payrate[0].rateruntail / 2 : item.price * payrate[0].rateruntail :
                                    item.type === 'วิ่งบน' ? item.reward === '1' ? item.price * payrate[0].rateruntop / 2 : item.price * payrate[0].rateruntop :
                                    item.type === 'วิ่งล่าง' ? item.reward === '1' ? item.price * payrate[0].raterunbottom / 2 : item.price * payrate[0].raterunbottom : ''
                                }</td>
                                <td><Trash size={15} onClick={() => handleDelete(item.huainumber, item.type)}/></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>
        </div>

    )
}

export default User_History