import { React, useState, useEffect } from 'react'
import axios from 'axios'
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Navbar, Form, Button, Row, Col, Table, Nav } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Trash } from 'react-bootstrap-icons'
import Box from '@mui/material/Box';
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';
import 'animate.css'
import "./style.css"
import api from '../instants';

function User_Main() {
    const navigate = useNavigate()
    const param = useParams()

    var [huainumber, sethuainumber] = useState('')
    var [head, sethead] = useState('')
    var [tail, settail] = useState('')
    var [toadhead, settoadhead] = useState('')
    var [toadtail, settoadtail] = useState('')
    var [top, settop] = useState('')
    var [bottom, setbottom] = useState('')
    var [fourtimes, setfourtimes] = useState('')
    var [runhead, setrunhead] = useState('')
    var [runtail, setruntail] = useState('')
    var [runtop, setruntop] = useState('')
    var [runbottom, setrunbottom] = useState('')
    var [headtail, setheadtail] = useState('')
    var [toadheadtail, settoadheadtail] = useState('')
    var [topbottom, settopbottom] = useState('')
    var [runheadtail, setrunheadtail] = useState('')
    var [runtopbottom, setruntopbottom] = useState('')
    var [swap, setswap] = useState(false)
    
    function timeout(delay) {
        return new Promise( res => setTimeout(res, Number(delay)))
    }

    // submit all data to database
    const SubmitAll = () => {
        Swal.fire({
            title: 'แทงหวยทั้งหมด',
            text: "คุณต้องการแทงหวยทั้งหมดใช่ไหม",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ยกเลิก',
            allowOutsideClick: false
          }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'กำลังแทงหวย',
                    text: 'กรุณารอสักครู่',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                })
                for(const data of formData) {
                    await api.post('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Create_Record', {
                        huainumber: data.huainumber,
                        type: data.type,
                        price: data.price,
                        addby: param.id,
                        reward: data.payratehalf
                    }).then((res) => {
                        console.log(res)
                    }).catch((err) => {
                        console.log(err);
                        navigate('/')
                    })
                }
                Swal.close()
                Swal.fire({
                    icon: 'success',
                    title: 'แทงหวยสำเร็จ',
                    showConfirmButton: false,
                    timer: 1000
                })
                await timeout(1000)
                navigate('/User_Main/' + param.id + '/User_History')
                
            }
        })
    }

    const [systemStatus, setSystemStatus] = useState()
    const [deniedNumber, setDeniedNumber] = useState([])
    const [payhalfNumber, setPayhalfNumber] = useState([])
    const [maxPrice, setMaxPrice] = useState()
    const [sumNumber, setSumNumber] = useState()

    const fetchSumNumber = async () => {
        await fetch('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Get_Summarize_Record',{
            headers:{
                'authorization': localStorage.getItem('ACCESS_TOKEN'),
            }
        })
        .then((response) => response.json())
        .then((data) => setSumNumber(data))
        .catch((err) => {
            console.log(err);
            navigate('/')
        })
    }

    const fetchSystemStatus = async () => {
        await fetch('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Get_SystemStatus',{
            headers:{
                'authorization': localStorage.getItem('ACCESS_TOKEN'),
            }
        })
        .then((response) => response.json())
        .then((data) => {
            setSystemStatus(data[0].system_status)
            if(data[0].system_status === 0) {
                Swal.fire({
                    title: "ระบบกำลังปิดอยู่",
                    text: "ไม่สามารถแทงหวยตอนนี้ได้",
                    icon: 'error'
                })
            }
            
        })
        .catch((err) => {
            console.log(err);
            navigate('/')
        })
    }

    const fetchDeniedNumber = async () => {
        await fetch('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Get_DeniedNumber',{
            headers:{
                'authorization': localStorage.getItem('ACCESS_TOKEN'),
            }
        })
        .then((response) => response.json())
        .then((data) => setDeniedNumber(data))
        .catch((err) => {
            console.log(err);
            navigate('/')
        })
            
    }

    const fetchPayhalfNumber = async () => {
        await fetch('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Get_PayhalfNumber',{
            headers:{
                'authorization': localStorage.getItem('ACCESS_TOKEN'),
            }
        })
        .then((response) => response.json())
        .then((data) => setPayhalfNumber(data))
        .catch((err) => {
            console.log(err);
            navigate('/')
        })
    }

    const fetchMaxPrice = async () => {
        await fetch('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Get_MaxPrice',{
            headers:{
                'authorization': localStorage.getItem('ACCESS_TOKEN'),
            }
        })
        .then((response) => response.json())
        .then((data) => setMaxPrice(data[0]))
        .catch((err) => {
            console.log(err);
            navigate('/')
        })
            
    }

    // fetch payrate data
    const [payrate, setpayrate] = useState('')
    const fetchPayRate = async () => {
        await fetch('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Get_PayRate/' + param.id,{
            headers:{
                'authorization': localStorage.getItem('ACCESS_TOKEN'),
            }
        })
        .then((response) => response.json())
        .then((data) => setpayrate(data))
        .catch((err) => {
            console.log(err);
            navigate('/')
        })
    }
    
    useEffect(() => {
        fetchSystemStatus()
        fetchDeniedNumber()
        fetchPayhalfNumber()
        fetchMaxPrice()
        fetchSumNumber()
        fetchPayRate()
    }, [])

    // x3x6 feature
    function generatePermutations(chars, current, used, result) {
        if (current.length === chars.length) {
            result.push(current.join(''))
            return
        }
        for (let i = 0; i < chars.length; i++) {
            if (!used[i]) {
                used[i] = true
                current.push(chars[i])
                generatePermutations(chars, current, used, result)
                current.pop()
                used[i] = false
            }
        }
    }
    function generateAllUniquePermutations(inputString) {
        const chars = inputString.split('')
        const used = new Array(chars.length).fill(false)
        const result = []
        generatePermutations(chars, [], used, result)
        return Array.from(new Set(result))
    }
    
    function removeDuplicates(arr) {
        return arr.filter((item,index) => arr.indexOf(item) === index)
    }

    // x3x6 swap activate
    function swapCheck() {
        setswap(!swap)
    }

    // feature add data in basket and show real time
    const [showBasketTable, setShowBasketTable] = useState(false)
    const [formData, setFormData] = useState([]); 

    // feature remember price after add data into array
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    // feature delete data in basket
    const handleDelete = (id) => {
        const updatedItems = formData.filter(item => item.id !== id)
        setFormData(updatedItems)
    }

    // sum of all price
    const sum = formData.reduce((total, obj) => {
        const objSum = Object.entries(obj).reduce((acc, [key, value]) => {
            if (key === 'price') {
                return Number(acc) + Number(value)
            }
            return Number(acc)
        }, 0)
        return Number(total) + Number(objSum)
    }, 0)
    

    // add data to array of data
    function addDataToFormData(huainumber, type, price) {
        if(!isNaN(price)) {
            let temp_type = type
            if(temp_type === 'head') {
                temp_type = 'หัว'
            } else if(temp_type === 'tail') {
                temp_type = 'ท้าย'
            } else if(temp_type === 'toadhead') {
                temp_type = 'โต๊ดหัว'
            } else if(temp_type === 'toadtail') {
                temp_type = 'โต๊ดท้าย'
            } else if(temp_type === 'fourtimes') {
                temp_type = '4 ครั้ง'
            } else if(temp_type === 'top') {
                temp_type = 'บน'
            } else if(temp_type === 'bottom') {
                temp_type = 'ล่าง'
            } else if(temp_type === 'runhead') {
                temp_type = 'วิ่งหัว'
            } else if(temp_type === 'runtail') {
                temp_type = 'วิ่งท้าย'
            } else if(temp_type === 'runtop') {
                temp_type = 'วิ่งบน'
            } else if(temp_type === 'runbottom') {
                temp_type = 'วิ่งล่าง'
            }

            let deniednumber_status = false
            for(const data of deniedNumber) {
                if(huainumber === data.denied_number) {
                    deniednumber_status = true
                }
            }
            let exist_price = 0
            for(const data of formData) {
                if(data.huainumber === huainumber && data.type === type) {
                    exist_price = exist_price + data.price
                }
            }
            if(deniednumber_status === false) {
                let rejectBuy = false
                let checkprice = sumNumber.filter((item) => {return item.huainumber === huainumber && item.type === temp_type ? item.price : false})
                if(checkprice === false) {
                    checkprice = Number(checkprice[0].price)
                } else (
                    checkprice = 0
                )
                if((type === 'head' || type === 'tail') && checkprice + Number(price) + exist_price > maxPrice.maxprice_headtail) {
                    rejectBuy = true
                    Swal.fire({
                        title: "เลข " + Number(huainumber) + " ชนิด " + temp_type + " รับได้มากที่สุด " + Number(maxPrice.maxprice_headtail - Number(checkprice + exist_price)) + " บาท",
                        icon: 'error',
                    })
                } else if((type === 'toadhead' || type === 'toadtail') && checkprice + Number(price) + exist_price > maxPrice.maxprice_toadheadtail) {
                    rejectBuy = true
                    Swal.fire({
                        title: "เลข " + Number(huainumber) + " ชนิด " + temp_type + " รับได้มากที่สุด " + Number(maxPrice.maxprice_toadheadtail - Number(checkprice + exist_price)) + " บาท",
                        icon: 'error',
                    })
                } else if((type === 'top' || type === 'bottom') && checkprice + Number(price) + exist_price > maxPrice.maxprice_topbottom) {
                    rejectBuy = true
                    Swal.fire({
                        title: "เลข " + Number(huainumber) + " ชนิด " + temp_type + " รับได้มากที่สุด " + Number(maxPrice.maxprice_topbottom - Number(checkprice + exist_price)) + " บาท",
                        icon: 'error',
                    })
                }
                for(const data of payhalfNumber) {
                    if(huainumber === data.payhalf_number) {
                        Swal.fire({
                            title: "หวยเลข " + huainumber + " จ่ายครึ่ง",
                            icon: 'warning',
                            timer: 1500
                        })
                    }
                }
                let hasMatchingItem = payhalfNumber.some((item) => item.payhalf_number === huainumber)
                
                if(rejectBuy === false) {
                    const data = {
                        id: formData.length,
                        huainumber: huainumber,
                        type: type,
                        price: Number(price),
                        payratehalf: hasMatchingItem
                    }
                    formData.unshift(data)
                }
                
            } else {
                Swal.fire({
                    title: "ไม่รับแทงหวยเลข " + huainumber,
                    icon: 'error',
                    timer: 1500
                })
            }
        } else {
            alert('ราคาต้องเป็นตัวเลขเท่านั้น')
        }
        
    }

    function addNumber() {
        if(showHiddenContent_3number) {
            if(huainumber.length === 3) {
                if(
                    (Number(head) !== NaN && head) ||
                    (Number(tail) !== NaN && tail) ||
                    (Number(toadhead) !== NaN && toadhead) || 
                    (Number(toadtail) !== NaN && toadtail) ||
                    (Number(fourtimes) !== NaN && fourtimes) ||
                    (Number(headtail) !== NaN && headtail) ||
                    (Number(toadheadtail) !== NaN && toadheadtail)
                ) {
                    if(
                        swap &&
                        !(huainumber.substring(0,1) === huainumber.substring(1,2) &&
                        huainumber.substring(1,2) === huainumber.substring(2,3))
                        
                    ) {
                        const allPermutations = generateAllUniquePermutations(huainumber)
                        if(
                            (huainumber.substring(0,1) === huainumber.substring(1,2) ||
                            huainumber.substring(1,2) === huainumber.substring(2,3) ||
                            huainumber.substring(0,1) === huainumber.substring(2,3))
                        ) {
                            const allPermutationsremovearr = removeDuplicates(allPermutations)
                            if(headtail) {
                                let headPrice = Number(headtail) / 6
                                let tailPrice = Number(headtail) / 6
                                headPrice = parseInt(headPrice)
                                tailPrice = parseInt(tailPrice)
                                for(const data of allPermutationsremovearr) {
                                    addDataToFormData(data, 'head', headPrice)
                                    addDataToFormData(data, 'tail', tailPrice)
                                }
                            }
                            if(toadheadtail) {
                                let toadheadPrice = Number(toadheadtail) / 6
                                let toadtailPrice = Number(toadheadtail) / 6
                                toadheadPrice = parseInt(toadheadPrice)
                                toadtailPrice = parseInt(toadtailPrice)
                                for(const data of allPermutationsremovearr) {
                                    addDataToFormData(data, 'toadhead', toadheadPrice)
                                    addDataToFormData(data, 'toadtail', toadtailPrice)
                                }
                            }
                            if(head) {
                                let headPrice = Number(head) / 3
                                headPrice = parseInt(headPrice)
                                for(const data of allPermutationsremovearr) {
                                    addDataToFormData(data, 'head', headPrice)
                                }
                            }
                            if(tail) {
                                let tailPrice = Number(tail) / 3
                                tailPrice = parseInt(tailPrice)
                                for(const data of allPermutationsremovearr) {
                                    addDataToFormData(data, 'tail', tailPrice)
                                }
                            }
                            if(toadhead) {
                                let toadheadPrice = Number(toadhead) / 3
                                toadheadPrice = parseInt(toadheadPrice)
                                for(const data of allPermutationsremovearr) {
                                    addDataToFormData(data, 'toadhead', toadheadPrice)
                                }
                            }
                            if(toadtail) {
                                let toadtailPrice = Number(toadtail) / 3
                                toadtailPrice = parseInt(toadtailPrice)
                                for(const data of allPermutationsremovearr) {
                                    addDataToFormData(data, 'toadtail', toadtailPrice)
                                }
                            }
                            if(fourtimes) {
                                let fourtimesPrice = Number(fourtimes) / 3
                                fourtimesPrice = parseInt(fourtimesPrice)
                                for(const data of allPermutationsremovearr) {
                                    addDataToFormData(data, 'fourtimes', fourtimesPrice)
                                }
                            }
                        } else {
                            if(headtail) {
                                let headPrice = Number(headtail) / 12
                                let tailPrice = Number(headtail) / 12
                                headPrice = parseInt(headPrice)
                                tailPrice = parseInt(tailPrice)
                                for(const data of allPermutations) {
                                    addDataToFormData(data, 'head', headPrice)
                                    addDataToFormData(data, 'tail', tailPrice)
                                }
                            }
                            if(toadheadtail) {
                                let toadheadPrice = Number(toadheadtail) / 12
                                let toadtailPrice = Number(toadheadtail) / 12
                                toadheadPrice = parseInt(toadheadPrice)
                                toadtailPrice = parseInt(toadtailPrice)
                                for(const data of allPermutations) {
                                    addDataToFormData(data, 'toadhead', toadheadPrice)
                                    addDataToFormData(data, 'toadtail', toadtailPrice)
                                }
                            }
                            if(head) {
                                let headPrice = Number(head) / 6
                                headPrice = parseInt(headPrice)
                                for(const data of allPermutations) {
                                    addDataToFormData(data, 'head', headPrice)
                                }
                            }
                            if(tail) {
                                let tailPrice = Number(tail) / 6
                                tailPrice = parseInt(tailPrice)
                                for(const data of allPermutations) {
                                    addDataToFormData(data, 'tail', tailPrice)
                                }
                            }
                            if(toadhead) {
                                let toadheadPrice = Number(toadhead) / 6
                                toadheadPrice = parseInt(toadheadPrice)
                                for(const data of allPermutations) {
                                    addDataToFormData(data, 'toadhead', toadheadPrice)
                                }
                            }
                            if(toadtail) {
                                let toadtailPrice = Number(toadtail) / 6
                                toadtailPrice = parseInt(toadtailPrice)
                                for(const data of allPermutations) {
                                    addDataToFormData(data, 'toadtail', toadtailPrice)
                                }
                            }
                            if(fourtimes) {
                                let fourtimesPrice = Number(fourtimes) / 6
                                fourtimesPrice = parseInt(fourtimesPrice)
                                for(const data of allPermutations) {
                                    addDataToFormData(data, 'fourtimes', fourtimesPrice)
                                }
                            }
                        }
                    } else {
                        if(headtail) {
                            let headPrice = Number(headtail) / 2
                            let tailPrice = Number(headtail) / 2
                            headPrice = parseInt(headPrice)
                            tailPrice = parseInt(tailPrice)
                            addDataToFormData(huainumber, 'head', headPrice)
                            addDataToFormData(huainumber, 'tail', tailPrice)
                        }
                        if(toadheadtail) {
                            let toadheadPrice = Number(toadheadtail) / 2
                            let toadtailPrice = Number(toadheadtail) / 2
                            toadheadPrice = parseInt(toadheadPrice)
                            toadtailPrice = parseInt(toadtailPrice)
                            addDataToFormData(huainumber, 'toadhead', toadheadPrice)
                            addDataToFormData(huainumber, 'toadtail', toadtailPrice)
                        }
                        if(head) {
                            addDataToFormData(huainumber, 'head', head)
                        }
                        if(tail) {
                            addDataToFormData(huainumber, 'tail', tail)
                        }
                        if(toadhead) {
                            addDataToFormData(huainumber, 'toadhead', toadhead)
                        }
                        if(toadtail) {
                            addDataToFormData(huainumber, 'toadtail', toadtail)
                        }
                        if(fourtimes) {
                            addDataToFormData(huainumber, 'fourtimes', fourtimes)
                        }
                    }
                    console.log(formData)
                } else {
                    alert('ราคาแทงต้องเป็นตัวเลขเท่านั้น')
                }
            } else {
                alert('กรุณากรอกเลขหวยให้ครบ')
            }
        } else if(showHiddenContent_2number) {
            if(huainumber.length === 2) {
                if(
                    (Number(topbottom) !== NaN && topbottom) ||
                    (Number(top) !== NaN && top) ||
                    (Number(bottom) !== NaN && bottom)
                ) {
                    if(topbottom) {
                        let topPrice = Number(topbottom) / 2
                        let bottomPrice = Number(topbottom) / 2
                        topPrice = parseInt(topPrice)
                        bottomPrice = parseInt(bottomPrice)
                        addDataToFormData(huainumber, 'top', topPrice)
                        addDataToFormData(huainumber, 'bottom', bottomPrice)
                    }
                    if(top) {
                        addDataToFormData(huainumber, 'top', top)
                    }
                    if(bottom) {
                        addDataToFormData(huainumber, 'bottom', bottom)
                    }
                } else {
                    alert('ราคาแทงต้องเป็นตัวเลขเท่านั้น')
                }
            } else {
                alert('กรุณากรอกเลขหวยให้ครบ')
            }

        } else if(showHiddenContent_1number) {
            if(huainumber.length === 1) {
                if(
                    (Number(runtop) !== NaN && runtop) ||
                    (Number(runbottom) !== NaN && runbottom) ||
                    (Number(runhead) !== NaN && runhead) ||
                    (Number(runtail) !== NaN && runtail) ||
                    (Number(runheadtail) !== NaN && runheadtail) ||
                    (Number(runtopbottom) !== NaN && runtopbottom)
                ) {
                    if(runtopbottom) {
                        let runtopPrice = Number(runtopbottom) / 2
                        let runbottomPrice = Number(runtopbottom) / 2
                        runtopPrice = parseInt(runtopPrice)
                        runbottomPrice = parseInt(runbottomPrice)
                        addDataToFormData(huainumber, 'runtop', runtopPrice)
                        addDataToFormData(huainumber, 'runbottom', runbottomPrice)
                    }
                    if(runheadtail) {
                        let runheadPrice = Number(runheadtail) / 2
                        let runtailPrice = Number(runheadtail) / 2
                        runheadPrice = parseInt(runheadPrice)
                        runtailPrice = parseInt(runtailPrice)
                        addDataToFormData(huainumber, 'runhead', runheadPrice)
                        addDataToFormData(huainumber, 'runtail', runtailPrice)
                    }
                    if(runtop) {
                        addDataToFormData(huainumber, 'runtop', runtop)
                    }
                    if(runbottom) {
                        addDataToFormData(huainumber, 'runbottom', runbottom)
                    }
                    if(runhead) {
                        addDataToFormData(huainumber, 'runhead', runhead)
                    }
                    if(runtail) {
                        addDataToFormData(huainumber, 'runtail', runtail)
                    }
                }
            } else {
                alert('กรุณากรอกเลขหวย')
            }
        }
        if(formData.length > 0) {
            setShowBasketTable(true)
        }
        sethuainumber('')
        if(!isChecked) {
            sethead('')
            settail('')
            settoadhead('')
            settoadtail('')
            settop('')
            setbottom('')
            setfourtimes('')
            setrunhead('')
            setruntail('')
            setruntop('')
            setrunbottom('')
            setheadtail('')
            settoadheadtail('')
            settopbottom('')
            setrunheadtail('')
            setruntopbottom('')
            setswap(false)
        }
        
    }

    // hidden content 3number 2number and 1number
    const [showHiddenContent_1number, setShowHiddenContent_1number] = useState(false);
    const [showHiddenContent_2number, setShowHiddenContent_2number] = useState(false);
    const [showHiddenContent_3number, setShowHiddenContent_3number] = useState(true);
    const toggleShowContent1Number = () => {
        setShowHiddenContent_1number(true)
        setShowHiddenContent_2number(false)
        setShowHiddenContent_3number(false)
        sethuainumber('')
        sethead('')
        settail('')
        settoadhead('')
        settoadtail('')
        settop('')
        setbottom('')
        setfourtimes('')
        setrunhead('')
        setruntail('')
        setruntop('')
        setrunbottom('')
        setheadtail('')
        settoadheadtail('')
        settopbottom('')
        setrunheadtail('')
        setruntopbottom('')
        setswap(false)
    }
    const toggleShowContent2Number = () => {
        setShowHiddenContent_1number(false)
        setShowHiddenContent_2number(true)
        setShowHiddenContent_3number(false)
        sethuainumber('')
        sethead('')
        settail('')
        settoadhead('')
        settoadtail('')
        settop('')
        setbottom('')
        setfourtimes('')
        setrunhead('')
        setruntail('')
        setruntop('')
        setrunbottom('')
        setheadtail('')
        settoadheadtail('')
        settopbottom('')
        setrunheadtail('')
        setruntopbottom('')
        setswap(false)
    }
    const toggleShowContent3Number = () => {
        setShowHiddenContent_1number(false)
        setShowHiddenContent_2number(false)
        setShowHiddenContent_3number(true)
        sethuainumber('')
        sethead('')
        settail('')
        settoadhead('')
        settoadtail('')
        settop('')
        setbottom('')
        setfourtimes('')
        setrunhead('')
        setruntail('')
        setruntop('')
        setrunbottom('')
        setheadtail('')
        settoadheadtail('')
        settopbottom('')
        setrunheadtail('')
        setruntopbottom('')
        setswap(false)
    }

    // add huainumber string by clicking
    const addHuainumber = (number) => {
        if(showHiddenContent_3number) {
            if(huainumber.length < 3) {
                huainumber += number
                sethuainumber(huainumber)
            }
        } else if(showHiddenContent_2number) {
            if(huainumber.length < 2) {
                huainumber += number
                sethuainumber(huainumber)
            }
        } else if(showHiddenContent_1number) {
            if(huainumber.length < 1) {
                huainumber += number
                sethuainumber(huainumber)
            }
        }
    }

    // delete huainumber by clicking
    const delHuainumber = () => {
        huainumber = huainumber.slice(0,-1)
        sethuainumber(huainumber)
    }

    // fetch real time formdata
    useEffect(() => {
        fetchSystemStatus()
        console.log(formData); // Log the updated preFormData whenever it changes
    }, [formData]);

    function showpayrate() {
        let str =   'หัว: ' + payrate[0].ratehead + ' | ' + 'ท้าย: ' + payrate[0].ratetail +
                    '\nโต๊ดหัว: ' + payrate[0].ratetoadhead + ' | ' + 'โต๊ดท้าย: ' + payrate[0].ratetoadtail + 
                    '\n4 ครั้ง: ' + payrate[0].ratefourtimes + 
                    '\nบน: ' + payrate[0].ratetop + ' | ' + 'ล่าง: ' + payrate[0].ratebottom +
                    '\nวิ่งบน: ' + payrate[0].rateruntop + ' | ' + 'วิ่งล่าง: ' + payrate[0].raterunbottom + 
                    '\nวิ่งหัว: ' + payrate[0].raterunhead + ' | ' + 'วิ่งท้าย: ' + payrate[0].rateruntail
        Swal.fire({
            title: "อัตราจ่าย",
            html: '<pre>' + str + '</pre>',
            customClass: {
                popup: 'format-pre'
            },
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            }
        })
    }

    function showdeniednumber() {
        Swal.fire({
            title: "เลขที่ไม่รับแทง",
            html: deniedNumber.map((item) => (' ' + item.denied_number)),
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            }
        })
    }

    function showpayhalfnumber() {
        Swal.fire({
            title: "เลขที่จ่ายครึ่ง",
            html: payhalfNumber.map((item) => (' ' + item.payhalf_number)),
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            }
        })
    }

    return (
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
            <video
                src='https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1263950/5d55e33857c40b4ae57aaba611cf7ce8a2b41c5b.mp4'
                autoPlay loop muted playsInline
                style={{
                    position:"absolute",
                    width:"100%",
                    height: showBasketTable ? "100%" : "110vh",
                    objectFit:"cover",
                    zIndex:"-1"
                }}
            />

            <Container className='' style={{paddingLeft:"0", paddingRight:"0", maxWidth:"none", width:"100%"}}>
                {/* navbar header */}
                <Navbar style={{paddingLeft:"0", paddingRight:"0", maxWidth:"none", width:"100%"}} collapseOnSelect expand="md" className="bg-light bg-opacity-90 ">
                    <Container>
                        <Navbar.Brand className='d-flex align-items-end'>
                            <img src='/logo.png' width={50} height={50}/>
                            <Container>
                                <h3 style={{fontWeight:"bold"}}>ซื้อหวยออนไลน์</h3>
                            </Container>
                    
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link onClick={() => navigate("/User_Main/" + param.id + "/User_History")}>ประวัติการซื้อ</Nav.Link>
                                <Nav.Link onClick={() => showpayrate()}>อัตราจ่าย</Nav.Link>
                                <Nav.Link onClick={() => showdeniednumber()}>เลขที่ไม่รับแทง</Nav.Link>
                                <Nav.Link onClick={() => showpayhalfnumber()}>เลขที่จ่ายครึ่ง</Nav.Link>
                            </Nav>
                            <Nav>
                                <Navbar.Text>ชื่อผู้ใช้งาน: {param.id}</Navbar.Text>
                                <Nav.Link onClick={() => navigate("/")}>ออกจากระบบ</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                {/* seletor tab */}
                <Container className='p-3 w-90'>
                    <Nav justify variant="tabs" defaultActiveKey="3number" className='bg-light bg-opacity-50'>
                        <Nav.Item>
                            <Nav.Link eventKey="3number" className={toggleShowContent3Number ? "text-black" : "text-white"} onClick={toggleShowContent3Number}>สามตัว</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="2number" className={toggleShowContent3Number ? "text-black" : "text-white"} onClick={toggleShowContent2Number}>สองตัว</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="1number" className={toggleShowContent3Number ? "text-black" : "text-white"} onClick={toggleShowContent1Number}>เลขวิ่ง</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    {showHiddenContent_3number &&
                        <Container className='p-3 bg-light bg-opacity-25'>
                            <Container className="d-flex justify-content-center"> 
                                <p className='text-white'>กรุณากรอกราคาที่ต้องการจะแทง</p>
                            </Container>
                            <Container className="d-flex justify-content-center mb-2 ">
                                <Row className='w-100'>
                                    <Form.Group as={Col}>
                                        <Form.Control value={headtail} pattern="[0-9]*" type="text" placeholder='หัวท้าย' onChange={(event) => {
                                            setheadtail(event.target.value)
                                        }}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Control value={toadheadtail} pattern="[0-9]*" type="text" placeholder="โต๊ดหัวท้าย" onChange={(event) => {
                                            settoadheadtail(event.target.value)
                                        }}/>
                                    </Form.Group>
                                </Row>
                            </Container>
                            <Container className="d-flex justify-content-center mb-2">
                                <Row className='w-100'>
                                    <Form.Group as={Col}>
                                        <Form.Control value={head}  pattern="[0-9]*" type="text" placeholder="หัว" onChange={(event) => {
                                            sethead(event.target.value)
                                        }}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Control value={toadhead} pattern="[0-9]*" type="text" placeholder="โต๊ดหัว" onChange={(event) => {
                                            settoadhead(event.target.value)
                                        }}/>
                                    </Form.Group>
                                </Row>
                            </Container>
                            <Container className="d-flex justify-content-center mb-2">
                                <Row className='w-100'>
                                    <Form.Group as={Col}>
                                        <Form.Control value={tail} pattern="[0-9]*" type="text" placeholder="ท้าย" onChange={(event) => {
                                            settail(event.target.value)
                                        }}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Control value={toadtail}  pattern="[0-9]*" type="text" placeholder="โต๊ตท้าย" onChange={(event) => {
                                            settoadtail(event.target.value)
                                        }}/>
                                    </Form.Group>
                                </Row>
                            </Container>
                            <Container className="d-flex justify-content-center mb-2">
                                <Row className='w-100'>
                                    <Form.Group as={Col}>
                                        <Form.Control value={fourtimes} pattern="[0-9]*" type="text" placeholder="4 ครั้ง" onChange={(event) => {
                                            setfourtimes(event.target.value)
                                        }}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Button style={{fontWeight:"bold"}} className='rounded-pill w-100' variant={swap ? "success" : "light"} size='md' onClick={() => swapCheck()}>กลับ</Button>
                                    </Form.Group>
                                </Row>
                            </Container>
                            <Container className="d-flex justify-content-center mb-2">
                                <Row className='w-50'>
                                    <Button style={{fontWeight:"bold"}} className='rounded-pill' variant={isChecked ? "success" : "light"} size='md' onClick={() => handleCheckboxChange()}>จำราคา</Button>
                                </Row>
                            </Container>
                        </Container>
                    }
                    {showHiddenContent_2number &&
                        <Container className='p-3 bg-light bg-opacity-25'>
                            <Container className="d-flex justify-content-center"> 
                                <p className='text-white'>กรุณากรอกราคาที่ต้องการจะแทง</p>
                            </Container>
                            <Container className="d-flex justify-content-center mb-2">
                                <Row className='w-100'>
                                    <Form.Group as={Col}>
                                        <Form.Control value={topbottom}  pattern="[0-9]*" type="text" placeholder="บนล่าง" onChange={(event) => {
                                            settopbottom(event.target.value)
                                        }}/>
                                    </Form.Group>
                                </Row>
                            </Container>
                            <Container className='d-flex justify-content-center mb-2'>
                                <Row className='w-100'>
                                    <Form.Group as={Col}>
                                        <Form.Control value={top} pattern="[0-9]*" type="text" placeholder="บน" onChange={(event) => {
                                            settop(event.target.value)
                                        }}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Control value={bottom}  pattern="[0-9]*" type="text" placeholder="ล่าง" onChange={(event) => {
                                            setbottom(event.target.value)
                                        }}/>
                                    </Form.Group>
                                </Row>
                            </Container>
                            <Container className="d-flex justify-content-center mb-2">
                                <Row className='w-50'>
                                    <Button style={{fontWeight:"bold"}} className='rounded-pill' variant={isChecked ? "success" : "light"} size='md' onClick={() => handleCheckboxChange()}>จำราคา</Button>
                                </Row>
                            </Container>
                        </Container>
                    }
                    {showHiddenContent_1number && 
                        <Container className='p-3 bg-light bg-opacity-25'>
                            <Container className="d-flex justify-content-center"> 
                                <p className='text-white'>กรุณากรอกราคาที่ต้องการจะแทง</p>
                            </Container>
                            <Container className="d-flex justify-content-center mb-2">
                                <Row className='w-100'>
                                    <Form.Group as={Col}>
                                        <Form.Control value={runtopbottom} pattern="[0-9]*" type="text" placeholder="วิ่งบนล่าง" onChange={(event) => {
                                            setruntopbottom(event.target.value)
                                        }}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Control value={runheadtail} pattern="[0-9]*" type="text" placeholder="วิ่งหัวท้าย" onChange={(event) => {
                                            setrunheadtail(event.target.value)
                                        }}/>
                                    </Form.Group>
                                </Row>
                            </Container>
                            <Container className="d-flex justify-content-center mb-2">
                                <Row className='w-100'>
                                    <Form.Group as={Col}>
                                        <Form.Control value={runtop} pattern="[0-9]*" type="text" placeholder="วื่งบน" onChange={(event) => {
                                            setruntop(event.target.value)
                                        }} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Control value={runhead}  pattern="[0-9]*" type="text" placeholder="วื่งหัว" onChange={(event) => {
                                            setrunhead(event.target.value)
                                        }}/>
                                    </Form.Group>
                                </Row>
                            </Container>
                            <Container className="d-flex justify-content-center mb-2"> 
                                <Row className='w-100'>
                                    <Form.Group as={Col}>
                                        <Form.Control value={runbottom} pattern="[0-9]*" type="text" placeholder="วื่งล่าง" onChange={(event) => {
                                            setrunbottom(event.target.value)
                                        }} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Control value={runtail} pattern="[0-9]*" type="text" placeholder="วื่งท้าย" onChange={(event) => {
                                            setruntail(event.target.value)
                                        }}/>
                                    </Form.Group>
                                </Row>
                            </Container>
                            <Container className="d-flex justify-content-center mb-2">
                                <Row className='w-50'>
                                    <Button style={{fontWeight:"bold"}} className='rounded-pill' variant={isChecked ? "success" : "light"} size='md' onClick={() => handleCheckboxChange()}>จำราคา</Button>
                                </Row>
                            </Container>
                        </Container>
                    }

                    {/* box show huainumber */}
                    <Container className='bg-light bg-opacity-25'>
                        <Form.Label as={Col} className='d-flex align-items-center justify-content-center text-white' style={{fontWeight:"bold"}}>เลือกเลขหวย</Form.Label>
                        <Container className='d-flex justify-content-center'>
                            {showHiddenContent_3number &&
                                <Container className='d-flex justify-content-center'>
                                    <Box
                                        className='d-flex justify-content-center align-items-center rounded-3'
                                        sx={{
                                            width: 50,
                                            height: 50,
                                            backgroundColor: 'transparent',
                                            borderStyle: 'solid',
                                            borderColor: 'white'
                                        }}
                                    >
                                        <h1 className='text-white'>{huainumber.substring(0,1)}</h1>
                                    </Box>
                                    <Box
                                        className='d-flex justify-content-center align-items-center rounded-3'
                                        sx={{
                                            width: 50,
                                            height: 50,
                                            marginLeft: 1,
                                            marginRight: 1,
                                            backgroundColor: 'transparent',
                                            borderStyle: 'solid',
                                            borderColor: 'white'
                                        }}
                                    >
                                        <h1 className='text-white'>{huainumber.substring(1,2)}</h1>
                                    </Box>
                                    <Box
                                        className='d-flex justify-content-center align-items-center rounded-3'
                                        sx={{
                                            width: 50,
                                            height: 50,
                                            backgroundColor: 'transparent',
                                            borderStyle: 'solid',
                                            borderColor: 'white'
                                        }}
                                    >
                                        <h1 className='text-white'>{huainumber.substring(2,3)}</h1>
                                    </Box>
                                </Container>
                            }
                            {showHiddenContent_2number &&
                                <Container className='d-flex justify-content-center'>
                                    <Box
                                        className='d-flex justify-content-center align-items-center rounded-3'
                                        sx={{
                                            width: 50,
                                            height: 50,
                                            backgroundColor: 'transparent',
                                            borderStyle: 'solid',
                                            borderColor: 'white'
                                        }}
                                    >
                                        <h1 className='text-white'>{huainumber.substring(0,1)}</h1>
                                    </Box>
                                    <Box
                                        className='d-flex justify-content-center align-items-center rounded-3'
                                        sx={{
                                            width: 50,
                                            height: 50,
                                            marginLeft: 1,
                                            backgroundColor: 'transparent',
                                            borderStyle: 'solid',
                                            borderColor: 'white'
                                        }}
                                    >
                                        <h1 className='text-white'>{huainumber.substring(1,2)}</h1>
                                    </Box>
                                </Container>
                            }
                            {showHiddenContent_1number && 
                                <Box
                                    className='d-flex justify-content-center align-items-center rounded-3'
                                    sx={{
                                        width: 50,
                                        height: 50,
                                        backgroundColor: 'transparent',
                                        borderStyle: 'solid',
                                        borderColor: 'white'
                                    }}
                                >
                                    <h1 className='text-white'>{huainumber.substring(0,1)}</h1>
                                </Box>
                            }
                        </Container>
                    </Container>
                    <Container className='p-3 bg-light bg-opacity-25'>
                        <Container className="d-flex justify-content-center mb-2">
                            <Row className='w-100'>
                                <Button as={Col} style={{fontWeight:"bold", borderColor:"black"}} className='rounded-pill' variant="light" size='md' onClick={() => addHuainumber('1')}>1</Button>
                                <Button as={Col} style={{marginLeft:"20px", marginRight:"20px", fontWeight:"bold", borderColor:"black"}} className='rounded-pill' variant="light" size='md' onClick={() => addHuainumber('2')}>2</Button>
                                <Button as={Col} style={{fontWeight:"bold", borderColor:"black"}} className='rounded-pill' variant="light" size='md' onClick={() => addHuainumber('3')}>3</Button>
                            </Row>
                        </Container>
                        <Container className="d-flex justify-content-center mb-2">
                            <Row className='w-100'>
                                <Button as={Col} style={{fontWeight:"bold", borderColor:"black"}} className='rounded-pill' variant="light" size='md' onClick={() => addHuainumber('4')}>4</Button>
                                <Button as={Col} style={{marginLeft:"20px", marginRight:"20px", fontWeight:"bold", borderColor:"black"}} className='rounded-pill' variant="light" size='md' onClick={() => addHuainumber('5')}>5</Button>
                                <Button as={Col} style={{fontWeight:"bold", borderColor:"black"}} className='rounded-pill' variant="light" size='md' onClick={() => addHuainumber('6')}>6</Button>
                            </Row>
                        </Container>
                        <Container className="d-flex justify-content-center mb-2">
                            <Row className='w-100'>
                                <Button as={Col} style={{fontWeight:"bold", borderColor:"black"}} className='rounded-pill' variant="light"  size='md' onClick={() => addHuainumber('7')}>7</Button>
                                <Button as={Col} style={{marginLeft:"20px", marginRight:"20px", fontWeight:"bold", borderColor:"black"}} className='rounded-pill' variant="light" size='md' onClick={() => addHuainumber('8')}>8</Button>
                                <Button as={Col} style={{fontWeight:"bold", borderColor:"black"}} className='rounded-pill' variant="light" size='md' onClick={() => addHuainumber('9')}>9</Button>
                            </Row>
                        </Container>
                        <Container className="d-flex justify-content-center align-middle">
                            <Row className='w-100'>
                                <Button as={Col} style={{fontWeight:"bold"}} className='rounded-pill' variant="danger" size='md' onClick={() => delHuainumber()}>ลบ</Button>
                                <Button as={Col} style={{marginLeft:"20px", marginRight:"20px", fontWeight:"bold", borderColor:"black"}} className='rounded-pill' variant="light" size='md' onClick={() => addHuainumber('0')}>0</Button>
                                <Button as={Col} style={{fontWeight:"bold"}} className='rounded-pill' variant="success" size='md' onClick={() => addNumber()}>เพิ่ม</Button>
                            </Row>
                        </Container>
                    </Container>
                </Container>
                
            </Container>
            <Container className='w-100'>
                {showBasketTable === true ?
                    <Container className='p-3'>
                        <Container className='p-3 bg-light bg-opacity-25'>
                                <Container className='d-flex justify-content-center p-3 text-black bg-white opacity-90'>
                                    <h3 style={{fontWeight:"bold"}}>รายการแทง</h3>
                                </Container>
                            <Table className='text-center table-light opacity-75'>
                                <thead>
                                    <tr>
                                        <th style={{minWidth:"15px"}}>เลข</th>
                                        <th style={{minWidth:"80px"}}>ชนิด</th>
                                        <th>ราคา</th>
                                        <th>รางวัล</th>
                                        <th>ลบ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formData.map((item) => (
                                        <tr key={item.id}>
                                            <td>{item.huainumber}</td>
                                            <td>{
                                                item.type === 'head' ? 'หัว' :
                                                item.type === 'tail' ? 'ท้าย' :
                                                item.type === 'toadhead' ? 'โต๊ดหัว' :
                                                item.type === 'toadtail' ? 'โต๊ดท้าย' :
                                                item.type === 'top' ? 'บน' :
                                                item.type === 'bottom' ? 'ล่าง' :
                                                item.type === 'fourtimes' ? '4 ครั้ง' :
                                                item.type === 'runhead' ? 'วิ่งหัว' :
                                                item.type === 'runtail' ? 'วิ่งท้าย' :
                                                item.type === 'runtop' ? 'วิ่งบน' :
                                                item.type === 'runbottom' ? 'วิ่งล่าง' : ''
                                            }</td>
                                            <td>{item.price}</td>
                                            <td>{
                                                item.type === 'head' ? item.payratehalf === true ? item.price * payrate[0].ratehead / 2 : item.price * payrate[0].ratehead :
                                                item.type === 'tail' ? item.payratehalf === true ? item.price * payrate[0].ratetail / 2 : item.price * payrate[0].ratetail :
                                                item.type === 'toadhead' ? item.payratehalf === true ? item.price * payrate[0].ratetoadhead / 2 : item.price * payrate[0].ratetoadhead :
                                                item.type === 'toadtail' ? item.payratehalf === true ? item.price * payrate[0].ratetoadtail / 2 : item.price * payrate[0].ratetoadtail :
                                                item.type === 'top' ? item.payratehalf === true ? item.price * payrate[0].ratetop / 2 : item.price * payrate[0].ratetop :
                                                item.type === 'bottom' ? item.payratehalf === true ? item.price * payrate[0].ratebottom / 2 : item.price * payrate[0].ratebottom :
                                                item.type === 'fourtimes' ? item.payratehalf === true ? item.price * payrate[0].ratefourtimes / 2 : item.price * payrate[0].ratefourtimes :
                                                item.type === 'runhead' ? item.payratehalf === true ? item.price * payrate[0].raterunhead / 2 : item.price * payrate[0].raterunhead :
                                                item.type === 'runtail' ? item.payratehalf === true ? item.price * payrate[0].rateruntail / 2 : item.price * payrate[0].rateruntail :
                                                item.type === 'runtop' ? item.payratehalf === true ? item.price * payrate[0].rateruntop / 2 : item.price * payrate[0].rateruntop :
                                                item.type === 'runbottom' ? item.payratehalf === true ? item.price * payrate[0].raterunbottom / 2 : item.price * payrate[0].raterunbottom : ''
                                            }</td>
                                            <td>
                                                <Trash onClick={() => handleDelete(item.id)}/>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                           
                                {Number(payrate[0].discount) > 0 ? 
                                    <Container className='text-white'>
                                        <Container className='d-flex justify-content-center p-1'>
                                            <h5 style={{fontWeight:"bold"}}>ราคาแทง {sum} บาท</h5>
                                        </Container>
                                        <Container className='d-flex justify-content-center p-1'>
                                            <h5 style={{fontWeight:"bold"}}>ส่วนลด {parseInt(sum * Number(payrate[0].discount) / 100)} บาท</h5>
                                        </Container>
                                        <Container className='d-flex justify-content-center p-1'>
                                            <h5 style={{fontWeight:"bold"}}>รวมราคาทั้งหมด {sum - parseInt(sum * Number(payrate[0].discount) / 100)} บาท</h5>
                                        </Container>
                                    </Container>
                                :
                                    <Container className='d-flex justify-content-center text-white p-1'>
                                        <h5 style={{fontWeight:"bold"}}>รวมราคาทั้งหมด {sum} บาท</h5>
                                    </Container>
                                }
                                
                            {systemStatus === 1? 
                                <Container className='d-flex justify-content-center text-white p-1'>
                                    <Button style={{fontWeight:"bold"}} className='rounded-pill' variant="success" size='lg' onClick={SubmitAll}>ซื้อหวยทั้งหมด</Button>
                                </Container> 
                            : <Container className='d-flex justify-content-center text-danger p-1'>
                                <h4 style={{fontWeight:"bold"}}>ระบบกำลังปิดอยู่ ไม่สามารถแทงหวยได้</h4>
                            </Container>}
                            
                        </Container>         
                    </Container> : ''
                }
                
            </Container>
        </div>
    )
}

export default User_Main