import  { React, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Form, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios'

function Login() {
    const navigate = useNavigate();
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    function handleSubmit(event) {
        event.preventDefault()
        axios.post('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/login', { username, password })
        .then((res) => {
            console.log(res)
            // store token 
            localStorage.setItem("ACCESS_TOKEN", res.data.accessToken);
            if(res.data.data === 'Login Successfully as admin'){
                navigate('/Admin_Main', {state : {user : username}})
            } else if( res.data.data === 'Login Successfully as agent') {
                navigate('/Agent_Main/' + username, {state : {user : username}})
            } else if(res.data.data === 'Login Successfully as user'){
                navigate('/User_Main/' + username, {state : {user : username}})
            } else {
                alert('ชื่อผู้ใช้งาน หรือ รหัสผ่าน ไม่ถูกต้อง')
            }
        }).catch(err => console.log(err))
    }
    return (
        <div style={{
            position:'fixed',
            display:'flex',
            flexDirection: 'column',
            alignItems:'center',
            left:'0%',
            top:'0%',
            width:'100%',
            height:'100vh',
            overflowY:'scroll'}}>
            <video
                src='https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1263950/5d55e33857c40b4ae57aaba611cf7ce8a2b41c5b.mp4'
                autoPlay loop muted playsInline
                style={{
                    position:"absolute",
                    width:"100%",
                    height: "100%",
                    objectFit:"cover",
                    zIndex:"-1"
                }}
            />
            <Container className='position-absolute top-50 start-50 translate-middle bg-light p-5 bg-opacity-50 rounded-5 w-75'>
                <Form onSubmit={handleSubmit}>
                    <h3 className='d-flex justify-content-center p-3 font-weight-bold text-black' style={{fontWeight:"bold"}}>เข้าสู่ระบบ</h3>
                    <Form.Group className="mb-3">
                        <Form.Label className="mb-2 text-black" style={{fontWeight:"bold"}}>ชื่อผู้ใช้งาน</Form.Label>
                        <Form.Control type="username" placeholder='ชื่อผู้ใช้งาน' onChange = { e => setUsername(e.target.value)}/>
                    </Form.Group>
            
                    <Form.Group className="mb-4">
                        <Form.Label className="mb-2 text-black" style={{fontWeight:"bold"}}>รหัสผ่าน</Form.Label>
                        <Form.Control  type="password" placeholder='รหัสผ่าน' onChange = { e => setPassword(e.target.value)}/>
                    </Form.Group>
                    <Container className='d-flex justify-content-center p-2'>
                        <Button className='text-black' style={{fontWeight:"bold"}} variant="light" type="submit" size='lg'>
                            เข้าสู่ระบบ
                        </Button>
                    </Container>
                </Form>
            </Container>
        </div>
        
    )
}

export default Login