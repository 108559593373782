import { React, useState, useEffect } from "react";
import { Navbar, Container, Nav, Table, Form, InputGroup, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";

function Anonymous() {
    const navigate = useNavigate();

    const [numberList, setNumberList] = useState([])
    const fetchRecord = async () => {
        await fetch('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Get_Summarize_Record_anonymous')
        .then((response) => response.json())
        .then((data) => setNumberList(data))
    }

    const [overPrice2, setOverPrice2] = useState()
    const [overPrice3, setOverPrice3] = useState()
    const [overPriceTH, setOverPriceTH] = useState()
    const [overPriceTT, setOverPriceTT] = useState()
    const fetchOverPrice = async () => {
        await fetch('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Get_OverPrice_anonymous')
        .then((response) => response.json())
        .then((data) => {
            setOverPrice2(data[0].price_ii)
            setOverPrice3(data[0].price_iii)
            setOverPriceTH(data[0].price_TH)
            setOverPriceTT(data[0].price_TT)
        })
    }
    
    useEffect(() => {
        fetchRecord()
        fetchOverPrice()
    }, [])

    const sumOfPrices = numberList
    .filter(item => item.huainumber.length === 2 && item.price > overPrice2)
    .map(item => item.price - overPrice2)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    const adjustedPrices = numberList
    .filter(item => {
      if (item.huainumber.length === 3) {
        if (item.type !== 'โต๊ดหัว' && item.type !== 'โต๊ดท้าย' && item.type !== '4 ครั้ง') {
          return item.price > overPrice3;
        } else if (item.type === 'โต๊ดหัว') {
          return item.price > overPriceTH;
        } else if (item.type === 'โต๊ดท้าย') {
          return item.price > overPriceTT;
        }
      }
      return false;
    })
    .map(item => {
      if (item.type !== 'โต๊ดหัว' && item.type !== 'โต๊ดท้าย') {
        return item.price - overPrice3;
      } else if (item.type === 'โต๊ดหัว') {
        return item.price - overPriceTH;
      } else {
        return item.price - overPriceTT;
      }
    });

    // Calculate the sum of adjusted prices
    const sumOfAdjustedPrices = adjustedPrices.reduce((accumulator, currentValue) => accumulator + currentValue, 0);


    return (
        <div>
            {/* navbar */}
            {/* <Container> */}
        
            {/* </Container> */}

            {/* show data */}
            <Container className="mt-5">
                <Table>
                    <thead>
                        <tr>
                            <th className="d-flex justify-content-end">ราคารวม</th>
                            <th className="w-50">{sumOfPrices + sumOfAdjustedPrices}</th>
                        </tr>
                    </thead>
                </Table>
                <Table>
                    <thead>
                        <tr>
                            <th>เลข</th>
                            <th>ชนิด</th>
                            <th>ราคา</th>
                        </tr>
                    </thead>
                    <tbody >
                        {numberList.filter((item) => { return item.huainumber.length === 2 ? item.price > overPrice2 : ''
                        }).map((item) => (
                            <tr key={item.id}>
                                <th>{item.huainumber}</th>
                                <td>{item.type}</td>
                                <td>{item.price - overPrice2}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tbody>
                        <tr>
                            <th>----</th>
                            <th>----</th>
                            <th>----</th>
                        </tr>
                    </tbody>
                    <tbody >
                        {numberList.filter((item) => { return item.huainumber.length === 3 && item.type !== 'โต๊ดหัว' && item.type !== 'โต๊ดท้าย' && item.type !== '4 ครั้ง'? item.price > overPrice3 : 
                        item.huainumber.length === 3 && item.type === 'โต๊ดหัว' ? item.price > overPriceTH :
                        item.huainumber.length === 3 && item.type === 'โต๊ดท้าย' ? item.price > overPriceTT :
                        ''
                        }).map((item) => (
                            <tr key={item.id}>
                                <th>{item.huainumber}</th>
                                <td>{item.type}</td>
                                <td>{
                                    item.type !== 'โต๊ดหัว' && item.type !== 'โต๊ดท้าย' ? item.price - overPrice3 :
                                    item.type === 'โต๊ดหัว' ? item.price - overPriceTH :
                                    item.price - overPriceTT
                                }</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>
        </div>
    )

}

export default Anonymous