import { React, useState, useEffect } from "react";
import { Navbar, Container, Nav, Table, Form, InputGroup, Button, Col } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import { PencilSquare } from "react-bootstrap-icons";
import api from "../instants";

function Admin_System() {
    const navigate = useNavigate();
    const [systemStatus, setSystemStatus] = useState()
    const [deniedNumber, setDeniedNumber] = useState([])
    const [payhalfNumber, setPayhalfNumber] = useState([])
    const [statusText, setStatusText] = useState('')
    const [maxPrice, setMaxPrice] = useState({})

    const fetchSystemStatus = async () => {
        await fetch('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Get_SystemStatus',{
            headers:{
                'authorization': localStorage.getItem('ACCESS_TOKEN'),
            }
        })
        .then((response) => response.json())
        .then((data) => {
            setSystemStatus(data[0].system_status)
            if(data[0].system_status === 1) {
                setStatusText('คุณต้องการจะปิดระบบ ใช่หรือไม่')
            } else if (data[0].system_status === 0) {
                setStatusText('คุณต้องการจะเปิดระบบ ใช่หรือไม่')
            }
        })
        .catch((err) => {
            console.log(err);
            navigate('/')
        });
    }

    const fetchDeniedNumber = async () => {
        await fetch('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Get_DeniedNumber',{
            headers:{
                'authorization': localStorage.getItem('ACCESS_TOKEN'),
            }
        })
        .then((response) => response.json())
        .then((data) => setDeniedNumber(data))
        .catch((err) => {
            console.log(err);
            navigate('/')
        });
    }

    const fetchPayhalfNumber = async () => {
        await fetch('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Get_PayhalfNumber',{
            headers:{
                'authorization': localStorage.getItem('ACCESS_TOKEN'),
            }
        })
        .then((response) => response.json())
        .then((data) => setPayhalfNumber(data))
        .catch((err) => {
            console.log(err);
            navigate('/')
        });
    }

    const fetchMaxPrice = async () => {
        await fetch('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Get_MaxPrice',{
            headers:{
                'authorization': localStorage.getItem('ACCESS_TOKEN'),
            }
        })
        .then((response) => response.json())
        .then((data) => {
            setMaxPrice(data[0])
        }).catch((err) => {
            console.log(err);
            navigate('/')
        });
            
    }

    const [overPrice2, setOverPrice2] = useState()
    const [overPrice3, setOverPrice3] = useState()
    const [overPriceTH, setOverPriceTH] = useState()
    const [overPriceTT, setOverPriceTT] = useState()
    const fetchOverPrice = async () => {
        await fetch('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Get_OverPrice',{
            headers:{
                'authorization': localStorage.getItem('ACCESS_TOKEN'),
            }
        })
        .then((response) => response.json())
        .then((data) => {
            setOverPrice2(data[0].price_ii)
            setOverPrice3(data[0].price_iii)
            setOverPriceTH(data[0].price_TH)
            setOverPriceTT(data[0].price_TT)
        }).catch((err) => {
            console.log(err);
            navigate('/')
        });
    }
    

    useEffect(() => {
        fetchSystemStatus()
        fetchMaxPrice()
        fetchDeniedNumber()
        fetchPayhalfNumber()
        fetchOverPrice()
    }, [])

    function changeStatus() {
        Swal.fire({
            title: statusText,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ยกเลิก',
            allowOutsideClick: false
          }).then(async (result) => {
            if (result.isConfirmed) {
                await api.post('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/System_ChangeStatus', {
                    status: systemStatus
                }).then((res) => {
                    console.log(res)
                }).catch((err) => {
                    console.log(err);
                    navigate('/')
                });
                window.location.reload()
            }
        })
    }

    async function changeMaxPrice_headtail() {
        const { value: MaxPrice } = await Swal.fire({
            title: 'แก้ไขราคารับแทงมากที่สุด',
            input: 'number',
            inputPlaceholder: 'ใส่ราคา',
            showCancelButton: true,
            confirmButtonText: 'แก้ไข',
            cancelButtonText: 'ยกเลิก',
            allowOutsideClick: false
        })
        if (MaxPrice) {
            await api.post('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/System_EditMaxPriceHeadTail', {number: MaxPrice})
            .then((res) => {
                console.log(res)
                Swal.fire(`แก้ไขราคามากที่สุดเป็น ${MaxPrice} แล้ว`)
            }).catch((err) => {
                console.log(err);
                navigate('/')
            });
            fetchMaxPrice()
        }
    }

    async function changeMaxPrice_toadheadtail() {
        const { value: MaxPrice } = await Swal.fire({
            title: 'แก้ไขราคารับแทงมากที่สุด',
            input: 'number',
            inputPlaceholder: 'ใส่ราคา',
            showCancelButton: true,
            confirmButtonText: 'แก้ไข',
            cancelButtonText: 'ยกเลิก',
            allowOutsideClick: false
        })
        if (MaxPrice) {
            await api.post('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/System_EditMaxPriceToadHeadTail', {number: MaxPrice})
            .then((res) => {
                console.log(res)
                Swal.fire(`แก้ไขราคามากที่สุดเป็น ${MaxPrice} แล้ว`)
            }).catch((err) => {
                console.log(err);
                navigate('/')
            });
            fetchMaxPrice()
        }
    }
    
    async function changeMaxPrice_topbottom() {
        const { value: MaxPrice } = await Swal.fire({
            title: 'แก้ไขราคารับแทงมากที่สุด',
            input: 'number',
            inputPlaceholder: 'ใส่ราคา',
            showCancelButton: true,
            confirmButtonText: 'แก้ไข',
            cancelButtonText: 'ยกเลิก',
            allowOutsideClick: false
        })
        if (MaxPrice) {
            await api.post('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/System_EditMaxPriceTopBottom', {number: MaxPrice})
            .then((res) => {
                console.log(res)
                Swal.fire(`แก้ไขราคามากที่สุดเป็น ${MaxPrice} แล้ว`)
            }).catch((err) => {
                console.log(err);
                navigate('/')
            });
            fetchMaxPrice()
        }
    }

    async function changeOverPrice2() {
        const { value: overPrice } = await Swal.fire({
            title: 'แก้ไขราคาตีออก 2 ตัว',
            input: 'number',
            inputPlaceholder: 'ใส่ราคาตีออก',
            showCancelButton: true,
            confirmButtonText: 'แก้ไข',
            cancelButtonText: 'ยกเลิก',
            allowOutsideClick: false
        })
        if (overPrice) {
            await api.post('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/System_EditOverPriceII', {number: overPrice})
            .then((res) => {
                console.log(res)
                Swal.fire(`แก้ไขราคาตีออก 2 ตัวเป็น ${overPrice} แล้ว`)
            }).catch((err) => {
                console.log(err);
                navigate('/')
            });
            fetchOverPrice()
        }
    }

    async function changeOverPrice3() {
        const { value: overPrice } = await Swal.fire({
            title: 'แก้ไขราคาตีออก 3 ตัว',
            input: 'number',
            inputPlaceholder: 'ใส่ราคาตีออก',
            showCancelButton: true,
            confirmButtonText: 'แก้ไข',
            cancelButtonText: 'ยกเลิก',
            allowOutsideClick: false
        })
        if (overPrice) {
            await api.post('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/System_EditOverPriceIII', {number: overPrice})
            .then((res) => {
                console.log(res)
                Swal.fire(`แก้ไขราคาตีออก 3 ตัวเป็น ${overPrice} แล้ว`)
            }).catch((err) => {
                console.log(err);
                navigate('/')
            });
            fetchOverPrice()
        }
    }

    async function changeOverPriceTH() {
        const { value: overPrice } = await Swal.fire({
            title: 'แก้ไขราคาตีออกโต๊ดหัว',
            input: 'number',
            inputPlaceholder: 'ใส่ราคาตีออก',
            showCancelButton: true,
            confirmButtonText: 'แก้ไข',
            cancelButtonText: 'ยกเลิก',
            allowOutsideClick: false
        })
        if (overPrice) {
            await api.post('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/System_EditOverPriceTH', {number: overPrice})
            .then((res) => {
                console.log(res)
                Swal.fire(`แก้ไขราคาตีออกโต๊ดหัวเป็น ${overPrice} แล้ว`)
            }).catch((err) => {
                console.log(err);
                navigate('/')
            });
            fetchOverPrice()
        }
    }

    async function changeOverPriceTT() {
        const { value: overPrice } = await Swal.fire({
            title: 'แก้ไขราคาตีออกโต๊ดท้าย',
            input: 'number',
            inputPlaceholder: 'ใส่ราคาตีออก',
            showCancelButton: true,
            confirmButtonText: 'แก้ไข',
            cancelButtonText: 'ยกเลิก',
            allowOutsideClick: false
        })
        if (overPrice) {
            await api.post('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/System_EditOverPriceTT', {number: overPrice})
            .then((res) => {
                console.log(res)
                Swal.fire(`แก้ไขราคาตีออกโต๊ดท้ายเป็น ${overPrice} แล้ว`)
            }).catch((err) => {
                console.log(err);
                navigate('/')
            });
            fetchOverPrice()
        }
    }


    const handleDeniedDelete = (id, number) => {
        Swal.fire({
            title: 'ลบเลข ' + number + ' ใช่หรือไม่',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ยกเลิก',
            allowOutsideClick: false
          }).then(async (result) => {
            if (result.isConfirmed) {
                await api.post('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/System_DeleteDeniedNumber', {
                    id: id
                }).then((res) => {
                    console.log(res)
                    fetchDeniedNumber()
                }).catch((err) => {
                    console.log(err);
                    navigate('/')
                });
            }
        })
    }

    const handleDeniedDeleteAll = () => {
        Swal.fire({
            title: 'ลบเลขไม่รับแทงทั้งหมดใช่หรือไม่',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ยกเลิก',
            allowOutsideClick: false
          }).then(async (result) => {
            if (result.isConfirmed) {
                await api.post('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/System_DeleteDeniedNumberAll')
                .then((res) => {
                    console.log(res)
                    window.location.reload()
                }).catch((err) => {
                    console.log(err);
                    navigate('/')
                });
            }
        })
    }

    async function addDeniedNumber() {
        const { value: DeniedNumber } = await Swal.fire({
            title: 'เพิ่มเลขที่ไม่รับแทง',
            input: 'text',
            inputPlaceholder: 'ใส่เลขหวย',
            inputAttributes: {
                maxlength: 3,
            },
            showCancelButton: true,
            confirmButtonText: 'เพิ่ม',
            cancelButtonText: 'ยกเลิก',
            allowOutsideClick: false
        })
        if (DeniedNumber) {
            let duplicate_denied = false
            let duplicate_payhalf = false
            for(const data of deniedNumber) {
                if(DeniedNumber === data.denied_number) {
                    duplicate_denied = true
                }
            }
            for(const data of payhalfNumber) {
                if(DeniedNumber === data.payhalf_number) {
                    duplicate_payhalf = true
                }
            }
            if(!duplicate_denied && !duplicate_payhalf) {
                if(!isNaN(Number(DeniedNumber))) {
                    await api.post('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/System_AddDeniedNumber', {number: DeniedNumber})
                    .then((res) => {
                        console.log(res)
                    }).catch((err) => {
                        console.log(err);
                        navigate('/')
                    });
                    fetchDeniedNumber()
                } else {
                    alert('เลขหวยต้องเป็นตัวเลขเท่านั้น')
                }
            } else if(!duplicate_payhalf) {
                alert('เลขหวยนี้ซ้ำกับเลขที่จ่ายครึ่ง')
            }
            
        }
    }

    const handlePayhalfDelete = (id, number) => {
        Swal.fire({
            title: 'ลบเลข ' + number + ' ใช่หรือไม่',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ยกเลิก',
            allowOutsideClick: false
          }).then(async (result) => {
            if (result.isConfirmed) {
                await api.post('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/System_DeletePayhalfNumber', {
                    id: id
                }).then((res) => {
                    console.log(res)
                    fetchPayhalfNumber()
                }).catch((err) => {
                    console.log(err);
                    navigate('/')
                });
            }
        })
    }

    const handlePayhalfDeleteAll = () => {
        Swal.fire({
            title: 'ลบเลขที่จ่ายครึ่งทั้งหมดใช่หรือไม่',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ยกเลิก',
            allowOutsideClick: false
          }).then(async (result) => {
            if (result.isConfirmed) {
                await api.post('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/System_DeletePayhalfNumberAll')
                .then((res) => {
                    console.log(res)
                    window.location.reload()
                }).catch((err) => {
                    console.log(err);
                    navigate('/')
                });
            }
        })
    }

    async function addPayhalfNumber() {
        const { value: PayhalfNumber } = await Swal.fire({
            title: 'เพิ่มเลขที่ไม่รับแทง',
            input: 'text',
            inputPlaceholder: 'ใส่เลขหวย',
            inputAttributes: {
                maxlength: 3,
            },
            showCancelButton: true,
            confirmButtonText: 'เพิ่ม',
            cancelButtonText: 'ยกเลิก',
            allowOutsideClick: false
        })
        if (PayhalfNumber) {
            let duplicate_denied = false
            let duplicate_payhalf = false
            for(const data of deniedNumber) {
                if(PayhalfNumber === data.denied_number) {
                    duplicate_denied = true
                }
            }
            for(const data of payhalfNumber) {
                if(PayhalfNumber === data.payhalf_number) {
                    duplicate_payhalf = true
                }
            }
            if(!duplicate_denied && !duplicate_payhalf) {
                if(!isNaN(Number(PayhalfNumber))) {
                    await api.post('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/System_AddPayhalfNumber', {number: PayhalfNumber})
                    .then((res) => {
                        console.log(res)
                    }).catch((err) => {
                        console.log(err);
                        navigate('/')
                    });
                    fetchPayhalfNumber()
                } else {
                    alert('เลขหวยต้องเป็นตัวเลขเท่านั้น')
                }
            } else if(!duplicate_payhalf) {
                alert('เลขหวยนี้ซ้ำกับเลขที่ไม่รับแทง')
            }
            
        }
    }

    function DeleteAllData() {
        Swal.fire({
            title: 'ต้องการลบข้อมูลเลขทั้งหมดใช่หรือไม่',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ยกเลิก',
            allowOutsideClick: false
          }).then(async (result) => {
            if (result.isConfirmed) {
                await api.post('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/System_CloneTable')
                .then((res) => {
                    console.log(res)
                    navigate('/Admin_Main')
                }).catch((err) => {
                    console.log(err);
                    navigate('/')
                });
            }
        })
    }

    return (
        <div>
            {/* navbar */}
            {/* <Container> */}
                <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
                    <Container>
                        <Navbar.Brand onClick={() => navigate("/Admin_Main")}>หน้าหลัก</Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link onClick={() => navigate("/Admin_Main/Admin_SubData")}>ดูข้อมูลอย่างละเอียด</Nav.Link>
                                <Nav.Link onClick={() => navigate("/Admin_Main/Admin_Over")}>ตีออก</Nav.Link>
                                <Nav.Link onClick={() => navigate("/Admin_Main/Admin_CheckUser")}>ดูข้อมูลผู้ใช้งาน</Nav.Link>
                                <Nav.Link onClick={() => navigate("/Admin_Main/Admin_CreateUser")}>สร้างผู้ใช้งาน</Nav.Link>
                                <Nav.Link onClick={() => navigate("/Admin_Main/Admin_PayRate")}>อัตราจ่าย-ส่วนลด</Nav.Link>
                                <Nav.Link onClick={() => navigate("/Admin_Main/Admin_System")}>ควบคุมระบบ</Nav.Link>
                            </Nav>
                            <Nav>
                                <Nav.Link onClick={() => navigate("/")}>Logout</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            {/* </Container> */}
            
            {/* system staus */}
            <Container className="d-flex mt-5 mb-5">
                <Container className="d-flex justify-content-end">
                    <h2 className={systemStatus === 1 ? 'text-success' : 'text-danger'}>
                        {systemStatus === 1 ? 'ระบบกำลังเปิดอยู่' : 'ระบบกำลังปิดอยู่'}
                    </h2>
                </Container>
                <Container className="d-flex justify-content-start">
                    <Button style={{fontWeight:"bold", borderColor:"black"}} className={systemStatus === 1 ? 'rounded-pill text-danger w-50' : 'rounded-pill text-success w-50'} variant="light" size='md' onClick={() => changeStatus()}>{systemStatus === 1 ? 'ปิดระบบ' : 'เปิดระบบ'}</Button>
                </Container>
            </Container>
            {/* max price */}
            <Container className="mb-5">
                <Container className="d-flex justify-content-center">
                    <Container className="d-flex justify-content-end">
                        <h2>ราคาหัวท้ายสูงสุด</h2>
                    </Container>
                    <Container className="d-flex justify-content-center w-25">
                        <h2>{maxPrice.maxprice_headtail}</h2>
                    </Container>
                    <Container className="d-flex justify-content-start align-items-center">
                        <PencilSquare size={30} onClick={() => {changeMaxPrice_headtail()}}/>
                    </Container>
                </Container>
                <Container className="d-flex justify-content-center">
                    <Container className="d-flex justify-content-end">
                        <h2>ราคาโต๊ดหัวท้ายสูงสุด</h2>
                    </Container>
                    <Container className="d-flex justify-content-center w-25">
                        <h2>{maxPrice.maxprice_toadheadtail}</h2>
                    </Container>
                    <Container className="d-flex justify-content-start align-items-center">
                        <PencilSquare size={30} onClick={() => {changeMaxPrice_toadheadtail()}}/>
                    </Container>
                </Container><Container className="d-flex justify-content-center">
                    <Container className="d-flex justify-content-end">
                        <h2>ราคาบนล่างสูงสุด</h2>
                    </Container>
                    <Container className="d-flex justify-content-center w-25">
                        <h2>{maxPrice.maxprice_topbottom}</h2>
                    </Container>
                    <Container className="d-flex justify-content-start align-items-center">
                        <PencilSquare size={30} onClick={() => {changeMaxPrice_topbottom()}}/>
                    </Container>
                </Container>
            </Container>

            {/* denied number */}
            <Container className="mb-5">
                <Container className="d-flex justify-content-center">
                    <Container className="d-flex justify-content-end">
                        <h2>เลขที่ไม่รับแทง</h2>
                    </Container>
                    <Container className="d-flex justify-content-start">
                        <Button style={{borderColor:"black"}} className='rounded-pill text-success w-50' variant="light" size='md' onClick={() => addDeniedNumber()}>เพิ่ม</Button>
                    </Container>
                </Container>
                <Container className="p-3 d-flex justify-content-center">
                    <div>
                        {deniedNumber.map((item) => (
                            <span style={{fontSize:'16pt'}} onClick={() => handleDeniedDelete(item.id, item.denied_number)}>
                                {item.denied_number + ' '}
                            </span>
                        ))}
                    </div>
                </Container>
                    <p className="d-flex justify-content-center">กดเลขเพื่อลบ</p>
                <Container className="d-flex justify-content-center">
                    <Button style={{borderColor:"black"}} className='rounded-pill text-danger w-50' variant="light" size='md'  onClick={() => handleDeniedDeleteAll()}>ลบเลขที่ไม่รับแทงทั้งหมด</Button>
                </Container>
            </Container>
            
            {/* payhalf number */}
            <Container>
                <Container className="d-flex justify-content-center">
                    <Container className="d-flex justify-content-end">
                        <h2>เลขที่จ่ายครึ่ง</h2>
                    </Container>
                    <Container className="d-flex justify-content-start">
                        <Button style={{borderColor:"black"}} className='rounded-pill text-success w-50' variant="light" size='md' onClick={() => addPayhalfNumber()}>เพิ่ม</Button>
                    </Container>
                </Container>
                <Container className="p-3 d-flex justify-content-center">
                    <div>
                        {payhalfNumber.map((item) => (
                            <span style={{fontSize:'16pt'}} onClick={() => handlePayhalfDelete(item.id, item.payhalf_number)}>
                                {item.payhalf_number + ' '}
                            </span>
                        ))}
                    </div>
                </Container>
                    <p className="d-flex justify-content-center">กดเลขเพื่อลบ</p>
                <Container className="d-flex justify-content-center">
                    <Button style={{borderColor:"black"}} className='rounded-pill text-danger w-50' variant="light" size='md'  onClick={() => handlePayhalfDeleteAll()}>ลบเลขที่จ่ายครึ่งทั้งหมด</Button>
                </Container>
            </Container>

            {/* overprice */}
            <Container className="mt-5">
                <Container className="d-flex justify-content-center">
                    <Container className="d-flex justify-content-end">
                        <h2>ราคาตีออก 2 ตัว</h2>
                    </Container>
                    <Container className="d-flex justify-content-center w-25">
                        <h2>{overPrice2}</h2>
                    </Container>
                    <Container className="d-flex justify-content-start align-items-center">
                        <PencilSquare size={30} onClick={() => {changeOverPrice2()}}/>
                    </Container>
                </Container>
            </Container>
            <Container>
                <Container className="d-flex justify-content-center">
                    <Container className="d-flex justify-content-end">
                        <h2>ราคาตีออก 3 ตัว</h2>
                    </Container>
                    <Container className="d-flex justify-content-center w-25">
                        <h2>{overPrice3}</h2>
                    </Container>
                    <Container className="d-flex justify-content-start align-items-center">
                        <PencilSquare size={30} onClick={() => {changeOverPrice3()}}/>
                    </Container>
                </Container>
            </Container>
            <Container>
                <Container className="d-flex justify-content-center">
                    <Container className="d-flex justify-content-end">
                        <h2>ราคาตีออกโต๊ดหัว</h2>
                    </Container>
                    <Container className="d-flex justify-content-center w-25">
                        <h2>{overPriceTH}</h2>
                    </Container>
                    <Container className="d-flex justify-content-start align-items-center">
                        <PencilSquare size={30} onClick={() => {changeOverPriceTH()}}/>
                    </Container>
                </Container>
            </Container>
            <Container>
                <Container className="d-flex justify-content-center">
                    <Container className="d-flex justify-content-end">
                        <h2>ราคาตีออกโต๊ดท้าย</h2>
                    </Container>
                    <Container className="d-flex justify-content-center w-25">
                        <h2>{overPriceTT}</h2>
                    </Container>
                    <Container className="d-flex justify-content-start align-items-center">
                        <PencilSquare size={30} onClick={() => {changeOverPriceTT()}}/>
                    </Container>
                </Container>
            </Container>
                                        

            {/* delete all */}
            <Container className="mt-5">
                <Container className="d-flex justify-content-center mb-3"> 
                    <Button style={{borderColor:"black"}} className='rounded-pill text-danger w-50' variant="light" size='md'  onClick={() => DeleteAllData()}>ลบข้อมูลหวยทั้งหมด</Button>
                </Container>
                <Container className="d-flex justify-content-center">
                    <Button style={{borderColor:"black"}} className='rounded-pill text-success w-50' variant="light" size='md'  onClick={() => window.open('/Admin_Main/History')}>ดูข้อมูลย้อนหลัง</Button>
                </Container>
            </Container>
        </div>
    )
}

export default Admin_System