import { React, useState, useEffect } from "react";
import { Navbar, Container, Nav, Table, Form, InputGroup, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import api from "../instants";

function Admin_Over() {
    const navigate = useNavigate();

    const [numberList, setNumberList] = useState([])
    const fetchRecord = async () => {
        await fetch('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Get_Summarize_Record',{
            headers:{
                'authorization': localStorage.getItem('ACCESS_TOKEN'),
            }
        })
        .then((response) => response.json())
        .then((data) => setNumberList(data))
        .catch((err) => {
            // console.log(err);
            navigate('/anonymous')
        });
    }

    const [overPrice2, setOverPrice2] = useState()
    const [overPrice3, setOverPrice3] = useState()
    const [overPriceTH, setOverPriceTH] = useState()
    const [overPriceTT, setOverPriceTT] = useState()
    const fetchOverPrice = async () => {
        await fetch('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Get_OverPrice',{
            headers:{
                'authorization': localStorage.getItem('ACCESS_TOKEN'),
            }
        })
        .then((response) => response.json())
        .then((data) => {
            setOverPrice2(data[0].price_ii)
            setOverPrice3(data[0].price_iii)
            setOverPriceTH(data[0].price_TH)
            setOverPriceTT(data[0].price_TT)
        }).catch((err) => {
            // console.log(err);
            navigate('/anonymous')
        });
    }
    
    useEffect(() => {
        fetchRecord()
        fetchOverPrice()
    }, [])

    const sumOfPrices = numberList
    .filter(item => item.huainumber.length === 2 && item.price > overPrice2)
    .map(item => item.price - overPrice2)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    const adjustedPrices = numberList
    .filter(item => {
      if (item.huainumber.length === 3) {
        if (item.type !== 'โต๊ดหัว' && item.type !== 'โต๊ดท้าย' && item.type !== '4 ครั้ง') {
          return item.price > overPrice3;
        } else if (item.type === 'โต๊ดหัว') {
          return item.price > overPriceTH;
        } else if (item.type === 'โต๊ดท้าย') {
          return item.price > overPriceTT;
        }
      }
      return false;
    })
    .map(item => {
      if (item.type !== 'โต๊ดหัว' && item.type !== 'โต๊ดท้าย') {
        return item.price - overPrice3;
      } else if (item.type === 'โต๊ดหัว') {
        return item.price - overPriceTH;
      } else {
        return item.price - overPriceTT;
      }
    });

    // Calculate the sum of adjusted prices
    const sumOfAdjustedPrices = adjustedPrices.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    // Admin_Over_CloneTable
    async function shareOver() {
        await api.post('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Admin_Over_CloneTable')
        .then((res) => {
            console.log(res)
            window.open("/anonymous", "_blank", "noreferrer");
        }).catch((err) => {
            console.log(err);
            navigate('/')
        });
    }


    return (
        <div>
            {/* navbar */}
            {/* <Container> */}
            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
                    <Container>
                        <Navbar.Brand onClick={() => navigate("/Admin_Main")}>หน้าหลัก</Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link onClick={() => navigate("/Admin_Main/Admin_SubData")}>ดูข้อมูลอย่างละเอียด</Nav.Link>
                                <Nav.Link onClick={() => navigate("/Admin_Main/Admin_Over")}>ตีออก</Nav.Link>
                                <Nav.Link onClick={() => navigate("/Admin_Main/Admin_CheckUser")}>ดูข้อมูลผู้ใช้งาน</Nav.Link>
                                <Nav.Link onClick={() => navigate("/Admin_Main/Admin_CreateUser")}>สร้างผู้ใช้งาน</Nav.Link>
                                <Nav.Link onClick={() => navigate("/Admin_Main/Admin_PayRate")}>อัตราจ่าย-ส่วนลด</Nav.Link>
                                <Nav.Link onClick={() => navigate("/Admin_Main/Admin_System")}>ควบคุมระบบ</Nav.Link>
                                
                            </Nav>
                            <Nav>
                                <Nav.Link onClick={() => navigate("/")}>Logout</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            {/* </Container> */}

            {/* show data */}
            <Container>
                <Table>
                    <thead>
                        <tr>
                            <th className="d-flex justify-content-end">ราคารวม</th>
                            <th className="w-50">{sumOfPrices + sumOfAdjustedPrices}</th>
                        </tr>
                    </thead>
                </Table>
                <Container className="d-flex justify-content-end">
                    <Button onClick={() => shareOver()}>บันทึกและแชร์</Button>
                </Container>
                
                <Table>
                    <thead>
                        <tr>
                            <th>เลข</th>
                            <th>ชนิด</th>
                            <th>ราคา</th>
                        </tr>
                    </thead>
                    <tbody >
                        {numberList.filter((item) => { return item.huainumber.length === 2 ? item.price > overPrice2 : ''
                        }).map((item) => (
                            <tr key={item.id}>
                                <th>{item.huainumber}</th>
                                <td>{item.type}</td>
                                <td>{item.price - overPrice2}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tbody>
                        <tr>
                            <th>----</th>
                            <th>----</th>
                            <th>----</th>
                        </tr>
                    </tbody>
                    <tbody >
                        {numberList.filter((item) => { return item.huainumber.length === 3 && item.type !== 'โต๊ดหัว' && item.type !== 'โต๊ดท้าย' && item.type !== '4 ครั้ง'? item.price > overPrice3 : 
                        item.huainumber.length === 3 && item.type === 'โต๊ดหัว' ? item.price > overPriceTH :
                        item.huainumber.length === 3 && item.type === 'โต๊ดท้าย' ? item.price > overPriceTT :
                        ''
                        }).map((item) => (
                            <tr key={item.id}>
                                <th>{item.huainumber}</th>
                                <td>{item.type}</td>
                                <td>{
                                    item.type !== 'โต๊ดหัว' && item.type !== 'โต๊ดท้าย' ? item.price - overPrice3 :
                                    item.type === 'โต๊ดหัว' ? item.price - overPriceTH :
                                    item.price - overPriceTT
                                }</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>
        </div>
    )

}

export default Admin_Over