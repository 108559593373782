import { React, useState, useEffect } from "react";
import { Navbar, Container, Nav, Table, Form, InputGroup, Button, Row, Col } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import Swal from 'sweetalert2'
import { useNavigate, useParams } from "react-router-dom";
import api from "../instants";

function Agent_CreateUser() {
    const navigate = useNavigate();
    const param = useParams()

    const [username, setUsername] = useState('')
    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')
    const [name, setName] = useState('')
    const [fullname, setFullname] = useState('')
    const [phonenumber, setPhonenumber] = useState('')
    const [head, sethead] = useState(500)
    const [tail, settail] = useState(500)
    const [toadhead, settoadhead] = useState(100)
    const [toadtail, settoadtail] = useState(100)
    const [top, settop] = useState(60)
    const [bottom, setbottom] = useState(60)
    const [fourtimes, setfourtimes] = useState(100)
    const [runhead, setrunhead] = useState(3)
    const [runtail, setruntail] = useState(3)
    const [runtop, setruntop] = useState(4)
    const [runbottom, setrunbottom] = useState(4)
    const [discount, setdiscount] = useState(0)

    const [userList, setUserList] = useState([])

    useEffect(() => {
        fetch('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Get_All_Users',{
            headers:{
                'authorization': localStorage.getItem('ACCESS_TOKEN'),
            }
        })
        .then((response) => response.json())
        .then((data) => setUserList(data))
        .catch((err) => {
            console.log(err);
            navigate('/')
        });
    }, []);

    function timeout(delay) {
        return new Promise( res => setTimeout(res, Number(delay)))
    }

    async function handleSubmit(event) {
        if(password1 !== password2) {
            event.preventDefault()
            alert("รหัสผ่านไม่ตรงกัน")
        } else if(userList.filter((item) => item.username.includes(username)).length > 0) {
            event.preventDefault()
            alert("มีชื่อผู้ใช้งานนี้อยู่ในระบบแล้ว")
        } else {
            Swal.fire({
                title: 'กำลังสร้างผู้ใช้งาน',
                didOpen: () => {
                    Swal.showLoading()
                },
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            })
            event.preventDefault()
            await api.post('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Admin_CreateUser', {
                username: username,
                password: password1,
                roll: 'user',
                addby: param.id,
                name: name,
                fullname: fullname,
                phonenumber: phonenumber
            }).then((res) => {
                console.log(res)
            }).catch((err) => {
                console.log(err);
                navigate('/')
            });
            
            event.preventDefault()
            await api.post('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Set_PayRate', {
                user: username,
                head: head,
                tail: tail,
                toadhead: toadhead,
                toadtail: toadtail,
                fourtimes: fourtimes,
                top: top,
                bottom: bottom,
                runhead: runhead,
                runtail: runtail,
                runtop: runtop,
                runbottom: runbottom,
                discount: discount
            }).then((res) => {
                console.log(res)
                Swal.close()
                Swal.fire({
                    icon: 'success',
                    title: 'สร้างผู้ใช้งานสำเร็จ',
                    showConfirmButton: false,
                    timer: 1000
                })
                
            }).catch((err) => {
                console.log(err);
                navigate('/')
            });

            await timeout(1000)
            navigate('/Agent_Main/' + param.id + '/Agent_CheckUser')
        }
    }

    return (
        <div>
            {/* navbar */}
            {/* <Container> */}
                <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
                    <Container>
                    <Navbar.Brand onClick={() => navigate("/Agent_Main/" + param.id)}>หน้าหลัก</Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link onClick={() => navigate("/Agent_Main/" + param.id + "/Agent_SubData")}>ดูข้อมูลอย่างละเอียด</Nav.Link>
                                <Nav.Link onClick={() => navigate("/Agent_Main/" + param.id + "/Agent_CheckUser")}>ดูข้อมูลผู้ใช้งาน</Nav.Link>
                                <Nav.Link onClick={() => navigate("/Agent_Main/" + param.id + "/Agent_CreateUser")}>สร้างผู้ใช้งาน</Nav.Link>
                                <Nav.Link onClick={() => navigate("/Agent_Main/" + param.id + "/Agent_PayRate")}>อัตราจ่าย-ส่วนลด</Nav.Link>
                            </Nav>
                            <Nav>
                                <Navbar.Text>ชื่อผู้ใช้งาน: {param.id}</Navbar.Text>
                                <Nav.Link onClick={() => navigate("/")}>Logout</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            {/* </Container> */}

            <Container className='p-5 w-75'>
                <Form onSubmit={handleSubmit}>
                    <h3 className='d-flex justify-content-center p-3'>สร้างผู้ใช้งาน</h3>
                    <Form.Group className="mb-3">
                        <Form.Label>กำหนดชื่อผู้ใช้งาน</Form.Label>
                        <Form.Control required value={username} type="text" placeholder="Username" maxLength={20} onChange = { e => setUsername(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>กำหนดรหัสผ่านผู้ใช้งาน</Form.Label>
                        <Form.Control required value={password1} type="text" placeholder="Password" maxLength={20} onChange = { e => setPassword1(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>กำหนดรหัสผ่านผู้ใช้งาน อีกรอบ</Form.Label>
                        <Form.Control required value={password2} type="text" placeholder="Password" maxLength={20} onChange = { e => setPassword2(e.target.value)}/>
                    </Form.Group>
                    <Container className="d-flex justify-content-center mt-5">
                        <Form.Label>ข้อมูลเพิ่มเติม ใส่หรือไม่ใส่ก็ได้</Form.Label>
                    </Container>
                    <Form.Group className="mb-3">
                        <Form.Label>ชื่อเล่น</Form.Label>
                        <Form.Control type="text" value={name} placeholder="ชื่อเล่น" onChange = { e => setName(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>ชื่อจริง-นามสกุล</Form.Label>
                        <Form.Control type="text" value={fullname} placeholder="ชื่อจริง-นามสกุล" onChange = { e => setFullname(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>เบอร์โทร</Form.Label>
                        <Form.Control type="text" value={phonenumber} placeholder="เบอร์โทร" onChange = { e => setPhonenumber(e.target.value)}/>
                    </Form.Group>

                    <h3 className='d-flex justify-content-center p-3'>กำหนดอัตราจ่าย - ส่วนลด</h3>
                    <Row className='mb-3'>
                        <Form.Group as={Col}>
                            <Form.Label>อัตราจ่าย หัว</Form.Label>
                            <Form.Control required value={head} type="number" onChange = { e => sethead(e.target.value)}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>อัตราจ่าย ท้าย</Form.Label>
                            <Form.Control required value={tail} type="number" onChange = { e => settail(e.target.value)}/>
                        </Form.Group>
                    </Row>
                    <Row className='mb-3'>
                        <Form.Group as={Col}>
                            <Form.Label>อัตราจ่าย โต๊ดหัว</Form.Label>
                            <Form.Control required value={toadhead} type="number" onChange = { e => settoadhead(e.target.value)}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>อัตราจ่าย โต๊ดท้าย</Form.Label>
                            <Form.Control required value={toadtail} type="number" onChange = { e => settoadtail(e.target.value)}/>
                        </Form.Group>
                    </Row>
                    <Row className='mb-3'>
                        <Form.Group as={Col}>
                            <Form.Label>อัตราจ่าย 4 ครั้ง</Form.Label>
                            <Form.Control required value={fourtimes} type="number" onChange = { e => setfourtimes(e.target.value)}/>
                        </Form.Group>
                    </Row>
                    <Row className='mb-3'>
                        <Form.Group as={Col}>
                            <Form.Label>อัตราจ่าย บน</Form.Label>
                            <Form.Control required value={top} type="number" onChange = { e => settop(e.target.value)}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>อัตราจ่าย ล่าง</Form.Label>
                            <Form.Control required value={bottom} type="number" onChange = { e => setbottom(e.target.value)}/>
                        </Form.Group>
                    </Row>
                    <Row className='mb-3'>
                        <Form.Group as={Col}>
                            <Form.Label>อัตราจ่าย วิ่งบน</Form.Label>
                            <Form.Control required value={runtop} type="number" onChange = { e => setruntop(e.target.value)}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>อัตราจ่าย วิ่งล่าง</Form.Label>
                            <Form.Control required value={runbottom} type="number" onChange = { e => setrunbottom(e.target.value)}/>
                        </Form.Group>
                    </Row>
                    <Row className='mb-3'>
                        <Form.Group as={Col}>
                            <Form.Label>อัตราจ่าย วิ่งหัว</Form.Label>
                            <Form.Control required value={runhead} type="number" onChange = { e => setrunhead(e.target.value)}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>อัตราจ่าย วิ่งท้าย</Form.Label>
                            <Form.Control required value={runtail} type="number" onChange = { e => setruntail(e.target.value)}/>
                        </Form.Group>
                    </Row>
                    <Row className='mb-3'>
                        <Form.Group as={Col}>
                            <Form.Label>ส่วนลด</Form.Label>
                            <Form.Control required value={discount} type="number" onChange = { e => setdiscount(e.target.value)}/>
                        </Form.Group>
                    </Row>

                    <Container className='d-flex justify-content-center p-3'>
                        <Button variant="primary" size="lg" type="submit">
                            สมัครผู้ใช้งาน
                        </Button>
                    </Container>
                </Form>
            </Container>

            
        </div>
    )
}

export default Agent_CreateUser