import { React, useState, useEffect } from "react";
import { Navbar, Container, Nav, Table, Form, InputGroup, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import Swal from 'sweetalert2'
import { useNavigate, useParams } from "react-router-dom";
import { PencilSquare } from "react-bootstrap-icons";

function Agent_PayRate() {
    const navigate = useNavigate()
    const param = useParams()
    const [payratelist, setPayratelist] = useState([])

    useEffect(() => {
        fetch('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Get_PayRateMatchUser',{
            headers:{
                'authorization': localStorage.getItem('ACCESS_TOKEN'),
            }
        })
        .then((response) => response.json())
        .then((data) => setPayratelist(data))
        .catch((err) => {
            console.log(err);
            navigate('/')
        });
    }, []);

    const handleChange = (username) => {
        navigate('/Agent_Main/' + param.id + '/Agent_CheckUser/Agent_EditUser/' + username)
    }
    return (
        <div>
            {/* navbar */}
            {/* <Container> */}
            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
                    <Container>
                        <Navbar.Brand onClick={() => navigate("/Agent_Main/" + param.id)}>หน้าหลัก</Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link onClick={() => navigate("/Agent_Main/" + param.id + "/Agent_SubData")}>ดูข้อมูลอย่างละเอียด</Nav.Link>
                                <Nav.Link onClick={() => navigate("/Agent_Main/" + param.id + "/Agent_CheckUser")}>ดูข้อมูลผู้ใช้งาน</Nav.Link>
                                <Nav.Link onClick={() => navigate("/Agent_Main/" + param.id + "/Agent_CreateUser")}>สร้างผู้ใช้งาน</Nav.Link>
                                <Nav.Link onClick={() => navigate("/Agent_Main/" + param.id + "/Agent_PayRate")}>อัตราจ่าย-ส่วนลด</Nav.Link>
                            </Nav>
                            <Nav>
                                <Navbar.Text>ชื่อผู้ใช้งาน: {param.id}</Navbar.Text>
                                <Nav.Link onClick={() => navigate("/")}>ออกจากระบบ</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            {/* </Container> */}
            
            {/* show data */}
            <Container>
                <Table>
                    <thead>
                        <tr>
                            <th>ชื่อผู้ใช้งาน</th>
                            <th>ชื่อเล่น</th>
                            <th>สถานะ</th>
                            <th>เพิ่มโดย</th>
                            <th>ส่วนลด(%)</th>
                            <th>หัว</th>
                            <th>ท้าย</th>
                            <th>โต๊ดหัว</th>
                            <th>โต๊ดท้าย</th>
                            <th>4 ครั้ง</th>
                            <th>บน</th>
                            <th>ล่าง</th>
                            <th>วิ่งบน</th>
                            <th>วิ่งล่าง</th>
                            <th>วิ่งหัว</th>
                            <th>วิ่งท้าย</th>
                            <th>แก้ไข</th>
                        </tr>
                    </thead>
                    <tbody>
                        {payratelist.filter((item) => !item.username.includes("admin") && item.addby === param.id).map((item) => (
                            <tr key={item.id}>
                                <td>{item.username}</td>
                                <td>{item.name}</td>
                                <td>{item.roll}</td>
                                <td>{item.addby}</td>
                                <td>{item.discount}</td>
                                <td>{item.ratehead}</td>
                                <td>{item.ratetail}</td>
                                <td>{item.ratetoadhead}</td>
                                <td>{item.ratetoadtail}</td>
                                <td>{item.ratefourtimes}</td>
                                <td>{item.ratetop}</td>
                                <td>{item.ratebottom}</td>
                                <td>{item.rateruntop}</td>
                                <td>{item.raterunbottom}</td>
                                <td>{item.raterunhead}</td>
                                <td>{item.rateruntail}</td>
                                <td><PencilSquare onClick={() => handleChange(item.username)}/></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>
        </div>
    )
}

export default Agent_PayRate