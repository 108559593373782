import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Login from './pages/Login'
import User_Main from './user/User_Main'
import User_History from './pages/User_History'
import Admin_Main from './pages/Admin_Main'
import Admin_SubData from './pages/Admin_SubData'
import Admin_CreateUser from "./pages/Admin_CreateUser"
import Admin_CheckUser from "./pages/Admin_CheckUser"
import Admin_PayRate from "./pages/Admin_PayRate"
import Admin_EditUser from "./pages/Admin_EditUser"
import Admin_System from "./pages/Admin_System"
import Admin_History from "./pages/Admin_History"
import Admin_Over from "./pages/Admin_Over"
import Agent_Main from "./pages/Agent_Main"
import Agent_SubData from "./pages/Agent_SubData"
import Agent_CreateUser from "./pages/Agent_CreateUser"
import Agent_CheckUser from "./pages/Agent_CheckUser"
import Agent_EditUser from "./pages/Agent_EditUser"
import Agent_PayRate from "./pages/Agent_PayRate"
import Anonymous from "./pages/anonymous"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login />}/>
        <Route path='/User_Main/:id' element={<User_Main />}/>
        <Route path='/User_Main/:id/User_History' element={<User_History />}/>

        <Route path='/Admin_Main' element={<Admin_Main />}/>
        <Route path='/Admin_Main/Admin_SubData' element={<Admin_SubData />}/>
        <Route path='/Admin_Main/Admin_CreateUser' element={<Admin_CreateUser />}/>
        <Route path='/Admin_Main/Admin_CheckUser' element={<Admin_CheckUser />}/>
        <Route path='/Admin_Main/Admin_CheckUser/Admin_EditUser/:id' element={<Admin_EditUser />}/>
        <Route path='/Admin_Main/Admin_PayRate' element={<Admin_PayRate />}/>
        <Route path='/Admin_Main/Admin_System' element={<Admin_System />}/>
        <Route path='/Admin_Main/History' element={<Admin_History />}/>
        <Route path='/Admin_Main/Admin_Over' element={<Admin_Over />}/>

        <Route path='/Agent_Main/:id' element={<Agent_Main />}/>
        <Route path='/Agent_Main/:id/Agent_SubData' element={<Agent_SubData />}/>
        <Route path='/Agent_Main/:id/Agent_CreateUser' element={<Agent_CreateUser />}/>
        <Route path='/Agent_Main/:id/Agent_CheckUser' element={<Agent_CheckUser />}/>
        <Route path='/Agent_Main/:id/Agent_CheckUser/Agent_EditUser/:userid' element={<Agent_EditUser />}/>
        <Route path='/Agent_Main/:id/Agent_PayRate' element={<Agent_PayRate />}/>

        <Route path='/anonymous' element={<Anonymous />}/>
        
      </Routes>
    </BrowserRouter>
  )
}

export default App;
