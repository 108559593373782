import { React, useState, useEffect } from "react";
import { Navbar, Container, Nav, Table, Form, InputGroup, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import Swal from 'sweetalert2'
import { useNavigate, useParams } from "react-router-dom";

function Agent_Main() {
    const navigate = useNavigate();
    const param = useParams()
    
    const [searchhuai, setSearchHuai] = useState('')
    const [searchtype, setSearchType] = useState('')
    // fetch data from backend
    const [numberList, setNumberList] = useState([])
    const fetchRecord = async () => {
        await fetch('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Agent_Get_Summarize_Record',{
            headers:{
                'authorization': localStorage.getItem('ACCESS_TOKEN'),
            }
        })
        .then((response) => response.json())
        .then((data) => setNumberList(data))
        .catch((err) => {
            console.log(err);
            navigate('/')
        });
    }

    useEffect(() => {
        fetchRecord()
    }, [])

    return (
        <div>
            {/* navbar */}
            {/* <Container> */}
                <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
                    <Container>
                        <Navbar.Brand onClick={() => navigate("/Agent_Main/" + param.id)}>หน้าหลัก</Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link onClick={() => navigate("/Agent_Main/" + param.id + "/Agent_SubData")}>ดูข้อมูลอย่างละเอียด</Nav.Link>
                                <Nav.Link onClick={() => navigate("/Agent_Main/" + param.id + "/Agent_CheckUser")}>ดูข้อมูลผู้ใช้งาน</Nav.Link>
                                <Nav.Link onClick={() => navigate("/Agent_Main/" + param.id + "/Agent_CreateUser")}>สร้างผู้ใช้งาน</Nav.Link>
                                <Nav.Link onClick={() => navigate("/Agent_Main/" + param.id + "/Agent_PayRate")}>อัตราจ่าย-ส่วนลด</Nav.Link>
                            </Nav>
                            <Nav>
                                <Navbar.Text>ชื่อผู้ใช้งาน: {param.id}</Navbar.Text>
                                <Nav.Link onClick={() => navigate("/")}>ออกจากระบบ</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            {/* </Container> */}

            {/* search bar */}
            <Container>
                <Form>
                    <InputGroup className='my-3'>
                        <Form.Control style={{marginRight:'5px'}} placeholder='ค้นหาเลข' type="number" onChange={(e) => setSearchHuai(e.target.value)}/>
                        <Form.Control style={{marginLeft:'5px', marginRight:"5px"}} placeholder='ค้นหาชนิด' type="text" onChange={(e) => setSearchType(e.target.value)}/>
                    </InputGroup>
                </Form>
            </Container>

            {/* show data */}
            <Container>
                <Table>
                    <thead>
                        <tr>
                            <th>เลข</th>
                            <th>ชนิด</th>
                            <th>ราคา</th>
                        </tr>
                    </thead>
                    <tbody >
                        {numberList.filter((item) => { return searchhuai === '' && searchtype === '' ? item && item.whoaddthisuser === param.id : item.huainumber.includes(searchhuai) && item.type.includes(searchtype) && item.whoaddthisuser === param.id
                        }).map((item) => (
                            <tr key={item.id}>
                                <th>{item.huainumber}</th>
                                <td>{item.type}</td>
                                <td>{item.total_price}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>

        </div>
    )
}

export default Agent_Main