import { React, useState, useEffect } from "react";
import { Navbar, Container, Nav, Table, Form, InputGroup, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";

function Admin_SubData() {
    const navigate = useNavigate();
    const [searchhuai, setSearchHuai] = useState('')
    const [searchtype, setSearchType] = useState('')
    const [searchaddby, setSearchAddby] = useState('')
    
    // fetch data from backend
    const [numberList, setNumberList] = useState([])
    const fetchRecord = async () => {
        await fetch('https://xn--3-6wf5bi4i2a0hxc.com/HuaiOnline/Get_All_Record',{
            headers:{
                'authorization': localStorage.getItem('ACCESS_TOKEN'),
            }
        })
        .then((response) => response.json())
        .then((data) => setNumberList(data))
        .catch((err) => {
            console.log(err);
            navigate('/')
        });
    }

    useEffect(() => {
        fetchRecord()
    }, [])

    return (
        <div>
            {/* navbar */}
            {/* <Container> */}
                <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
                    <Container>
                        <Navbar.Brand onClick={() => navigate("/Admin_Main")}>หน้าหลัก</Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link onClick={() => navigate("/Admin_Main/Admin_SubData")}>ดูข้อมูลอย่างละเอียด</Nav.Link>
                                <Nav.Link onClick={() => navigate("/Admin_Main/Admin_Over")}>ตีออก</Nav.Link>
                                <Nav.Link onClick={() => navigate("/Admin_Main/Admin_CheckUser")}>ดูข้อมูลผู้ใช้งาน</Nav.Link>
                                <Nav.Link onClick={() => navigate("/Admin_Main/Admin_CreateUser")}>สร้างผู้ใช้งาน</Nav.Link>
                                <Nav.Link onClick={() => navigate("/Admin_Main/Admin_PayRate")}>อัตราจ่าย-ส่วนลด</Nav.Link>
                                <Nav.Link onClick={() => navigate("/Admin_Main/Admin_System")}>ควบคุมระบบ</Nav.Link>
                            </Nav>
                            <Nav>
                                <Nav.Link onClick={() => navigate("/")}>Logout</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            {/* </Container> */}

            {/* search bar */}
            <Container>
                <Form>
                    <InputGroup className='my-3'>
                        <Form.Control style={{marginRight:'5px'}} placeholder='ค้นหาเลข' type="number" onChange={(e) => setSearchHuai(e.target.value)}/>
                        <Form.Control style={{marginLeft:'5px', marginRight:"5px"}} placeholder='ค้นหาชนิด' type="text" onChange={(e) => setSearchType(e.target.value)}/>
                        <Form.Control style={{marginLeft:'5px', marginRight:"5px"}} placeholder='ค้นหาผู้ใช้' type="text" onChange={(e) => setSearchAddby(e.target.value)}/>
                    </InputGroup>
                </Form>
            </Container>

            {/* show data */}
            <Container>
                <Table>
                    <thead>
                        <tr>
                            <th>เลข</th>
                            <th>ชนิด</th>
                            <th>ราคา</th>
                            <th>อัตราจ่าย</th>
                            <th>เงินรางวัล</th>
                            <th>แทงโดย</th>
                            <th>วันที่และเวลาแทง</th>
                        </tr>
                    </thead>
                    <tbody >
                        {numberList.filter((item) => { return searchhuai === '' && searchtype === '' && searchaddby === '' ? item : item.huainumber.includes(searchhuai) && item.type.includes(searchtype) && item.addby.includes(searchaddby)
                        }).map((item) => (
                            <tr key={item.id}>
                                <th>{item.huainumber}</th>
                                <td>{item.type}</td>
                                <td>{item.price}</td>
                                <td>{
                                    item.type === 'หัว' ? item.reward === '1' ? item.ratehead / 2 : item.ratehead : 
                                    item.type === 'ท้าย' ? item.reward === '1' ? item.ratetail / 2 : item.ratetail : 
                                    item.type === 'โต๊ดหัว' ? item.reward === '1' ? item.ratetoadhead / 2 : item.ratetoadhead : 
                                    item.type === 'โต๊ดท้าย' ? item.reward === '1' ? item.ratetoadtail / 2 : item.ratetoadtail : 
                                    item.type === '4 ครั้ง' ? item.reward === '1' ? item.ratefourtimes / 2 : item.ratefourtimes : 
                                    item.type === 'บน' ? item.reward === '1' ? item.ratetop / 2 : item.ratetop : 
                                    item.type === 'ล่าง' ? item.reward === '1' ? item.ratebottom / 2 : item.ratebottom : 
                                    item.type === 'วิ่งบน' ? item.reward === '1' ? item.rateruntop / 2 : item.rateruntop : 
                                    item.type === 'วิ่งล่าง' ? item.reward === '1' ? item.raterunbottom / 2 : item.raterunbottom : 
                                    item.type === 'วิ่งหัว' ? item.reward === '1' ? item.raterunhead / 2 : item.raterunhead : 
                                    item.type === 'วิ่งท้าย' ? item.reward === '1' ? item.rateruntail / 2 : item.rateruntail : ''
                                }</td>
                                <td>{
                                    item.type === 'หัว' ? item.reward === '1' ? item.ratehead / 2 * item.price: item.ratehead * item.price : 
                                    item.type === 'ท้าย' ? item.reward === '1' ? item.ratetail / 2 * item.price: item.ratetail * item.price : 
                                    item.type === 'โต๊ดหัว' ? item.reward === '1' ? item.ratetoadhead / 2 * item.price: item.ratetoadhead * item.price : 
                                    item.type === 'โต๊ดท้าย' ? item.reward === '1' ? item.ratetoadtail / 2 * item.price: item.ratetoadtail * item.price : 
                                    item.type === '4 ครั้ง' ? item.reward === '1' ? item.ratefourtimes / 2 * item.price: item.ratefourtimes * item.price : 
                                    item.type === 'บน' ? item.reward === '1' ? item.ratetop / 2 * item.price: item.ratetop * item.price : 
                                    item.type === 'ล่าง' ? item.reward === '1' ? item.ratebottom / 2 * item.price: item.ratebottom * item.price : 
                                    item.type === 'วิ่งบน' ? item.reward === '1' ? item.rateruntop / 2 * item.price: item.rateruntop * item.price : 
                                    item.type === 'วิ่งล่าง' ? item.reward === '1' ? item.raterunbottom / 2 * item.price: item.raterunbottom * item.price : 
                                    item.type === 'วิ่งหัว' ? item.reward === '1' ? item.raterunhead / 2 * item.price: item.raterunhead * item.price : 
                                    item.type === 'วิ่งท้าย' ? item.reward === '1' ? item.rateruntail / 2 * item.price: item.rateruntail * item.price : ''
                                }</td>
                                <td>{item.addby}</td>
                                <td>{item.addwhen.substring(0, 19)}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>
        </div>
    )
}

export default Admin_SubData